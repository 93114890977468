import React from 'react';
import { useField } from "formik";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";

interface FormTextProps {
  name: string;
  value: string | undefined;
  placeholder: string;
  onChange: (value: string) => void;
}

const FormText = (props: FormTextProps) => {
  const [field, meta] = useField(props.name);
  return <InputTextarea
    {...field}
    placeholder={props.placeholder}
    className={classNames({'p-invalid': (meta.touched && meta.error)})}
    value={props.value}
    onChange={(e: any) => {
      props.onChange(e.target.value);
    }}/>;
};

export default FormText;
