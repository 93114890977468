import React from 'react';
import { render } from 'react-dom';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-components';
import moment from "moment";

import App from './App';

import 'moment/min/locales'
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './shared/index.css';


import { BrowserRouter as Router } from "react-router-dom";
import { init } from 'emailjs-com';
import { addLocale } from "primereact/api";
import { nl } from "../src/shared/utils/date.utils";
import Auth0ProviderWithHistory from "./shared/Auth0ProviderwithHistory";
import appConfig from "./config.app";
import ScrollToTop from "./shared/components/ScrollToTop";
import ReactGA from "react-ga4";

const trackingId = "G-9D7P7V5ZFX"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

init("user_Y9kGCPQbVv3OzSPh4cNJb");

moment.locale(['nl']);
// const wsLink = new WebSocketLink({
//     uri: `ws://localhost:4466/`,
//     options: {
//         reconnect: true
//     }
// });
addLocale('nl', nl);
const httpLink = createHttpLink({
  uri: appConfig.graphQLHost,
  credentials: 'same-origin',
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const link = split(
//     // split based on operation type
//     ({ query }) => {
//         const definition = getMainDefinition(query);
//         return (
//             definition.kind === 'OperationDefinition' &&
//             definition.operation === 'subscription'
//         );
//     },
//     wsLink,
//     httpLink,
// );

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

const WrappedApp = (
  <ApolloProvider client={client}>
    <Router>
      <Auth0ProviderWithHistory>
        <ScrollToTop/>
        <App/>
      </Auth0ProviderWithHistory>
    </Router>
  </ApolloProvider>
);

render(WrappedApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
