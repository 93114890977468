import gql from "graphql-tag";

export const GetUserInfoQuery = gql`
query GetUserInfo($email: String!) {
	user(where: {email: $email}) {
    id
    email
    name
    firstName
  }
}
`;

export const CheckUserQuery = gql`
query CheckUser($email: String!) {
	user(where: {email: $email}) {
    id
  }
}
`;
