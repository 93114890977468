import {deliveryWeeksForExtraSubscription, DeliveryWeeksForSubscription} from "./subscription.utils";
import {chain, compact, countBy, first, flatten, get, last, map, sum} from "lodash";
import {ageToFullEquivalent} from "./membership.utils";
import {asIso8601, calculateAgeAtStartOfSubscription, momentFromIso8601} from "./date.utils";
import moment from "moment";
import {AmbassadorOption, DeliveryLocation, ProductOrderCreateWithoutClientOrderInput} from "../../__generated__/types";
import {formatDoubleDigit} from "./currency.utils";
import {Member} from "shared/types/members.interface";
import {formulaBySubscriptionProductCode} from "./formula.utils";
import {FormulaPeriod} from "../types/types";
import {ambassadorDiscountPercentage} from "./ambassador.utils";
import {Moment} from "moment/moment";
import {TODAY} from "../config";

import {nextDelivery} from "./clientOrder.utils";

export const createSelfHarvestProductOrder = (
  deliveryWeeks: DeliveryWeeksForSubscription,
  subscriptionProduct: { productPrices: { value: number }[] | null } | null | undefined,
  subscriptionDetails: {
    members: Member[],
    subscriptionProductCode?: string | undefined,
  },
  productOrderId?: string | undefined,
): ProductOrderCreateWithoutClientOrderInput | undefined => {
  const {members, subscriptionProductCode} = subscriptionDetails;

  let priceExcl = last(compact(subscriptionProduct?.productPrices))?.value;
  if (subscriptionProduct && priceExcl) {
    let firstDeliveryWeek = first(deliveryWeeks.weeks);

    let nrOfFullEquivalents = sum(members
      .filter(m => !m.deleted)
      .map(m => ageToFullEquivalent(calculateAgeAtStartOfSubscription(m.dateOfBirth, moment(firstDeliveryWeek, 'YYYY-W')))));

    priceExcl = priceExcl * nrOfFullEquivalents;

    return {
      id: productOrderId,
      product: {connect: {code: subscriptionProductCode}},
      quantity: nrOfFullEquivalents,
      remark: `(Aantal volw. equivalent: ${formatDoubleDigit(nrOfFullEquivalents)})`,
      priceExcl,
      deliveries: {
        create: deliveryWeeks.weeks.map((week) => ({
          plannedDeliveryDate: asIso8601(moment(`${week} 1`, 'YYYY-W E')),
        }))
      }
    };
  }
};

export const createBasketProductOrder = (
  deliveryWeeks: DeliveryWeeksForSubscription,
  subscriptionProduct: { productPrices: { value: number }[] | null } | null | undefined,
  subscriptionDetails: {
    subscriptionProductCode?: string | undefined,
  },
  deliveryLocation: DeliveryLocation | undefined | null,
  deliveryLocationIsoWeekday: number,
  productOrderId?: string | undefined,
): (ProductOrderCreateWithoutClientOrderInput | undefined) => {
  const {subscriptionProductCode} = subscriptionDetails;
  if (subscriptionProduct) {
    const priceExcl = last(compact(subscriptionProduct.productPrices))?.value;
    if (subscriptionProductCode && subscriptionProduct && priceExcl) {
      return {
        id: productOrderId,
        product: {connect: {code: subscriptionProductCode}},
        quantity: 1,
        priceExcl,
        portions: portionsForSubscription(subscriptionProductCode),
        deliveries: {
          create: deliveryWeeks.weeks.map((week) => ({
            plannedDeliveryDate: asIso8601(moment(`${week} ${deliveryLocationIsoWeekday}`, 'YYYY-W E')),
            deliveryLocation: deliveryLocation
          }))
        }
      };
    }
  }
};


const portionsForSubscription = (subscriptionProductCode: string) => {
  let formula = formulaBySubscriptionProductCode(subscriptionProductCode);
  let portions = 0;

  if (formula) {
    let formulaPeriods: FormulaPeriod[] = compact(formula.periods);
    let matchingPeriod = formulaPeriods.find(p => [p.codeSmall, p.codeLarge, p.code].includes(subscriptionProductCode));

    let portionsFieldKey = subscriptionProductCode.includes("large") ? "portionsLarge" : "portionsSmall";

    if (matchingPeriod) {
      portions = get(matchingPeriod, portionsFieldKey, 0);
    }
  }
  return portions;
};

export const createBoxProductOrder = (nrOfBoxes: number | undefined, productOrderId?: string | undefined,) => {
  if (nrOfBoxes) {
    let nrOfBoxesOrder: ProductOrderCreateWithoutClientOrderInput = {
      id: productOrderId,
      product: {connect: {code: 'pakket-bak'}},
      quantity: nrOfBoxes,
      priceExcl: 0,
    };
    return nrOfBoxesOrder;
  }

  return undefined;
};


export const createAmbassadorProductOrder = (
  ambassador: AmbassadorOption | undefined | null,
  ambassadorProductData: { product: { id: string, productPrices: { value: number }[] | null } | null } | undefined,
  productOrderId?: string | undefined,
) => {
  let ambassadorProductOrder: ProductOrderCreateWithoutClientOrderInput | undefined = undefined;
  if (ambassador) {
    let product = ambassadorProductData?.product;
    let priceExcl = last(compact(product?.productPrices))?.value;
    if (product && priceExcl) {
      ambassadorProductOrder = {
        id: productOrderId,
        product: {connect: {id: product.id}},
        priceExcl,
        quantity: 1,
      };
    }
  }
  return ambassadorProductOrder;
};

export const createExtraProductSubscriptions = (
  subscriptionData: {
    subscriptionProductCode?: string | undefined,
    extraProducts: { [key: string]: { productId: string | undefined, frequencyCode?: string | undefined } }
  },
  ambassador: AmbassadorOption | undefined | null,
  deliveryLocation: DeliveryLocation | undefined | null,
  deliveryLocationIsoWeekday: number,
  startDate?: Moment,
  existingExtraProductOrders?: {
    id: string | null,
    ambassadorDiscount?: boolean,
    product: { id: string, productPrices: { value: number }[] | null } | null
  }[],
) => {
  let extraProductsData: ({ id: string | null, productPrices: { value: number }[] | null } | null)[] | null | undefined =
    map(existingExtraProductOrders, 'product');
  let extraProductOrders = chain(subscriptionData.extraProducts)
    .keys()
    .map((extraProductKey) => {
      let extraProduct = subscriptionData.extraProducts[extraProductKey];
      let deliveryWeeksForExtraProduct = deliveryWeeksForExtraSubscription(
        subscriptionData.subscriptionProductCode,
        extraProduct.frequencyCode,
        extraProductKey,
        startDate,
      );

      let product = compact(extraProductsData).find(p => p.id === extraProduct.productId);
      if (product) {
        const priceExcl = last(compact(product.productPrices))?.value;
        if (priceExcl && deliveryWeeksForExtraProduct) {
          let extraProductOrder: ProductOrderCreateWithoutClientOrderInput = {
            id: existingExtraProductOrders ? existingExtraProductOrders.find(po => !po.ambassadorDiscount && po.product?.id === product?.id)?.id : null,
            product: {connect: {id: product.id}},
            frequency: extraProduct.frequencyCode,
            quantity: 1,
            priceExcl: priceExcl * deliveryWeeksForExtraProduct.weeks.length,
            deliveries: {
              create: deliveryWeeksForExtraProduct.weeks.map((week) => ({
                plannedDeliveryDate: asIso8601(moment(`${week} ${deliveryLocationIsoWeekday}`, 'YYYY-W E')),
                deliveryLocation: deliveryLocation
              }))
            }
          };

          if (ambassador) {
            let discountPercentage = ambassadorDiscountPercentage(ambassador);
            if (discountPercentage > 0) {
              let extraProductAmbassadorDiscountOrder: ProductOrderCreateWithoutClientOrderInput = {
                id: existingExtraProductOrders ? existingExtraProductOrders.find(po => po.ambassadorDiscount && po.product?.id === product?.id)?.id : null,
                product: {connect: {id: product.id}},
                quantity: 1,
                priceExcl: -(priceExcl * deliveryWeeksForExtraProduct.weeks.length) * discountPercentage,
                ambassadorDiscount: true,
              };
              return [extraProductOrder, extraProductAmbassadorDiscountOrder];
            }
          }

          return extraProductOrder;
        }
      }
      return null;
    })
    .compact()
    .flatten();
  return extraProductOrders.value();
};

export type ProductOrderWithProductCode = {
  product?: { code: string | undefined | null } | undefined | null,
  deliveries?: {
    id: string,
    deliveryLocation?: DeliveryLocation | null,
    deliveryDate?: string | null | undefined,
    plannedDeliveryDate?: string | null | undefined,
  }[] | null,
};

const filterProductOrdersBySize = <T extends ProductOrderWithProductCode>(
    productOrders: T[],
    sizes?: ('medium' | 'large')[]
  ): T[] =>
    productOrders
      .filter(productOrder => {
        return compact(sizes).length === 0 || compact(sizes).findIndex(size => !!productOrder.product?.code && productOrder.product.code.indexOf(size) > -1) > -1
      })
;

const filterProductOrdersByPrefix = <T extends ProductOrderWithProductCode>(
    productOrders: T[],
    productCodePrefix: string,
    sizes?: ('medium' | 'large')[]
  ): T[] =>
    filterProductOrdersBySize(productOrders, sizes)
      .filter(productOrder => productOrder.product && productOrder.product.code?.startsWith(productCodePrefix))
;

export const filterBasketProductOrders = <T extends ProductOrderWithProductCode>(productOrders: T[],
                                                                                 sizes?: ('medium' | 'large')[]): T[] =>
  filterProductOrdersByPrefix(productOrders, "pakketten-2023", sizes);
export const filterFixedBasketProductOrders = <T extends ProductOrderWithProductCode>(productOrders: T[],
                                                                                      sizes?: ('medium' | 'large')[]): T[] =>
  filterProductOrdersByPrefix(productOrders, "pakketten-2023-fixed", sizes);

export const filterFlexBasketProductOrders = <T extends ProductOrderWithProductCode>(productOrders: T[],
                                                                                     sizes?: ('medium' | 'large')[]): T[] =>
  filterProductOrdersByPrefix(productOrders, "pakketten-2023-flex", sizes);

export const filterProductOrdersByDeliveryLocations = <T extends ProductOrderWithProductCode>(productOrders: T[],
                                                                                              deliveryLocation: DeliveryLocation[],
                                                                                              sizes?: ('medium' | 'large')[]): T[] =>
  filterProductOrdersByPrefix(productOrders, "pakketten-2023", sizes)
    .filter(productOrder => {
      let deliveryLocationForFirstDelivery = productOrder.deliveries && nextDelivery(compact(productOrder.deliveries))?.deliveryLocation;
      return deliveryLocationForFirstDelivery && deliveryLocation.includes(deliveryLocationForFirstDelivery);
    });

export const filterBasketItemsByDeliveryLocations = <T extends ProductOrderWithProductCode>(basketItemRows: T[],
                                                                                            deliveryLocation: DeliveryLocation[]): T[] =>
  filterProductOrdersBySize(basketItemRows)
    .filter(productOrder => {
      let deliveryLocationForFirstDelivery = productOrder.deliveries && nextDelivery(compact(productOrder.deliveries))?.deliveryLocation;
      return deliveryLocationForFirstDelivery && deliveryLocation.includes(deliveryLocationForFirstDelivery);
    });

export const productOrdersAreDelivered = (productOrders: {
  clientOrder?: { id: string },
  deliveries?: { deliveryDate?: string | undefined | null }[] | null | undefined,
  deliveryDate?: string | null | undefined
}[]) => {
  let productOrderIsDelivered;
  let deliveries = compact(flatten(map(productOrders, 'nextDelivery')));
  if (deliveries.length === 0) {
    productOrderIsDelivered = compact(map(productOrders, 'deliveryDate')).length === productOrders.length;
  } else {
    productOrderIsDelivered = deliveriesAreDelivered(compact(deliveries));
  }
  return productOrderIsDelivered;
};

export const deliveriesAreDelivered = (deliveries: { deliveryDate?: string | undefined | null }[]) => compact(deliveries)
  .filter((d) => !!d.deliveryDate).length === deliveries.length;


type ProductOrderWithAmbassadorFields = { product: { id: string } | undefined | null, ambassadorDiscount?: boolean };
export const findAmbassadorDiscountProductOrderForProduct = <T extends ProductOrderWithAmbassadorFields>(
  productOrders: T[],
  productId: string | undefined
): T | undefined =>
  productOrders.find(productOrder => productId === productOrder.product?.id && productOrder.ambassadorDiscount);


export const hasUpcomingDelivery = (productOrder: { deliveries: { plannedDeliveryDate: string }[] | null }) => {
  return compact(productOrder.deliveries).findIndex(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSameOrAfter(TODAY, 'day')) > -1;
};

export const firstPlannedDelivery = (deliveries: { deliveryLocation?: DeliveryLocation | undefined | null, deliveryDate?: string | undefined | null, plannedDeliveryDate?: string | undefined | null }[] | null | undefined) => {
  return chain(deliveries)
    .compact()
    .filter(delivery => (delivery.deliveryDate === null || delivery.deliveryDate === undefined))
    .orderBy(delivery => !!delivery.plannedDeliveryDate && momentFromIso8601(delivery.plannedDeliveryDate).milliseconds(), 'asc')
    .first()
    .value();
};

export const expirationInfoForProductOrder = <T extends ProductOrderWithProductCode>(productOrder: T): {
  delivered: number;
  undelivered: number;
  lastPlannedDeliveryDate: Moment | undefined;
} => {
  let deliveriesCount = countBy(productOrder.deliveries, delivery => !!delivery.deliveryDate ? 'delivered' : 'undelivered');
  return {
    delivered: deliveriesCount.delivered,
    undelivered: deliveriesCount.undelivered,
    lastPlannedDeliveryDate: compact(productOrder.deliveries).reduce((lastPlannedDeliveryDate: Moment | undefined, delivery) => {
      if (delivery.plannedDeliveryDate) {
        let plannedDeliveryDate = momentFromIso8601(delivery.plannedDeliveryDate);
        return lastPlannedDeliveryDate && lastPlannedDeliveryDate.isAfter(plannedDeliveryDate, 'd')
          ? lastPlannedDeliveryDate
          : plannedDeliveryDate;
      }
    }, undefined),
  };
}

export interface ProductOrderType {
  product?: { code?: string | null, category?: { code: string | null } | null } | null
  deliveries?: { deliveryLocation?: DeliveryLocation | undefined | null, deliveryDate?: string | undefined | null, plannedDeliveryDate?: string | undefined | null }[] | null | undefined
}

export const findProductOrderByProductCodePrefix =
  <T extends ProductOrderType>(productOrders: T[] | null | undefined, productCodePrefix: string): T | undefined => {
    return compact(productOrders).find(po => po.product?.code?.startsWith(productCodePrefix));
  };
export const findFixed15BasketProductOrder = <T extends ProductOrderType>(productOrders: T[] | null | undefined): T | undefined =>
  findProductOrderByProductCodePrefix(productOrders, 'pakketten-2023-fixed-15');
export const findFlexBasketProductOrder = <T extends ProductOrderType>(productOrders: T[] | null | undefined): T | undefined =>
  findProductOrderByProductCodePrefix(productOrders, 'pakketten-2023-flex');
export const findBasketProductOrder = <T extends ProductOrderType>(productOrders: T[] | null | undefined): T | undefined =>
  findProductOrderByProductCodePrefix(productOrders, 'pakketten-2023');
export const findBoxProductOrder = <T extends ProductOrderType>(productOrders: T[] | null | undefined): T | undefined =>
  findProductOrderByProductCodePrefix(productOrders, 'pakket-bak');
export const findAmbassadorProductOrder = <T extends ProductOrderType>(productOrders: T[] | null | undefined): T | undefined =>
  findProductOrderByProductCodePrefix(productOrders, 'ambassadeur-');
export const findSelfHarvestProductOrder = <T extends ProductOrderType>(productOrders: T[] | null | undefined): T | undefined =>
  findProductOrderByProductCodePrefix(productOrders, 'zelfoogst-2023');
export const findFixedBasketProductOrder = <T extends ProductOrderType>(productOrders: T[] | null | undefined): T | undefined =>
  findProductOrderByProductCodePrefix(productOrders, 'pakketten-2023-fixed');


export const findNextDeliveryDateForBasketProductOrder = <T extends ProductOrderType>(productOrders: T[] | null | undefined): Moment | undefined => {
  let basketProductOrder = findBasketProductOrder(productOrders);
  let nextDelivery = firstPlannedDelivery(basketProductOrder?.deliveries);
  if (nextDelivery?.plannedDeliveryDate) {
    return momentFromIso8601(nextDelivery.plannedDeliveryDate);
  }
  return undefined;
}
