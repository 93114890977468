import React from 'react';
import { Content, FullImage } from "../../App";
import { Grid } from "../home";
import { Link } from "react-router-dom";
import SelfHarvestFormula from "../../components/SelfHarvestFormula";
import { SubscriptionSubType } from "../../shared/types/types";
import { Questions } from "../BasketsPage/BasketsPage2";
import Footer from "../../components/Footer";

const SelfHarvestPage = () => {
  return <div>
    <FullImage img={'IMG_1658-landscape.jpeg'}/>

    <Content>
      <h1>Zelfoogst</h1>

      <Grid columns={'3fr 5fr'}>
        <div>

          <p>Hou je van ontdekken wat er in onze ‘openlucht’ groentewinkel te vinden valt en geniet je van
            rust en van frisse lucht opsnuiven? Dan ben je een geboren zelfoogster.
          </p>

          <p>Als zelfoogster kom je op ons veld en pluk je met je eigen handen, recht van het veld. Verser kan
            natuurlijk
            niet.
            Je hebt de vrijheid wat je oogst, hoeveel en wanneer. Het is echt zo eenvoudig! Als je komt oogsten, breng
            je
            zelf
            een mes mee en ook zakjes of bakjes om je groenten in te doen. Rieken, spades of een kruiwagen: dat vind je
            natuurlijk bij ons op het veld.</p>
          <p>Heb je kinderen? Wij ook! We hebben daarom een zandbak gezet en een schommel. Daarnaast kunnen ze
            natuurlijk
            ook mee komen snoepen van bessen, aardbeien of snoeptomaatjes.
          </p>
          <p>Op ons veld kom je andere zelfoogsters tegen, maar ook de boeren Willem en Dirk. Je kent dus echt de boer
            die
            jouw
            net getrokken prei geplant heeft. De voedselketen wordt niet korter dan dit! Vergeet dus voedselkilometers,
            distributie, transport etc.
          </p>
        </div>
        <div>
          <img src={'IMG_5575.jpg'} alt={'Zelfoogst'}/>
        </div>

      </Grid>
      <Grid columns={'2fr 1fr'}>
        <div style={{marginBottom: '2vh'}}>
          <SelfHarvestFormula/>
        </div>
        <div style={{alignSelf: 'center'}}>
          <h2>Aangevuld met...</h2>
          <p>
            Basisproducten zoals <strong>eieren, aardappelen of uien</strong> kan je ook bij ons bestellen. We produceren ze
            meestal niet op eigen veld. maar laten dit doen door bevriende bioboeren uit de buurt. <br/>
            Wij zetten deze wekelijks, tweewekelijks of maandelijks voor je klaar.
          </p>
        </div>
      </Grid>

      <h2>Word jij zelfoogster?</h2>

      <p>Ons veld is dagelijks open van zonsopgang tot zonsondergang, uitgezonderd zondagnamiddag. Je kan dus komen
        oogsten wanneer het jou past!</p>
      <p>Een <strong>nieuwsbrief</strong> houdt je op de hoogte van wat je mag oogsten, maar ook <strong>op het
        veld</strong> duiden we met <strong>kleurcodes</strong> aan
        wat er te oogsten valt:</p>

      <p><span style={{backgroundColor: 'red', color: 'white', padding: '0 2px'}}>Rood</span>: nog niet oogsten <br/>
        <span style={{backgroundColor: 'yellow', color: 'black', padding: '0 2px'}}>Geel</span>: oogstklaar, voor direct
        vers gebruik <br/>
        <span style={{backgroundColor: 'green', color: 'white', padding: '0 2px'}}>Groen</span>: oogstklaar, dit veldje
        moet leeg, dus je kan ook soep maken voor je vriezer te vullen!
      </p>
      <p>Hoeveel je plukt, hangt helemaal af van je gezinsgrootte. Uiteraard oogst je meer als je thuis drie goed
        groeiende kids hebt rondlopen… Logisch, maar niet onbelangrijk: je mag uiteraard enkel voor je eigen gezin
        oogsten.</p>
      <p>We vragen om tijdens het oogsten <strong>op de paadjes te blijven</strong> om de bodem niet aan te drukken.</p>
      <p>Bloemen snijden mag je enkel met een <strong>bloemenabonnement</strong>.</p>

      <h2>Ja, ik word zelfoogster!</h2>
      <p>Ga dan snel naar ons <Link to={'https://www.mijnboer.be/groentegeweld/inschrijven/zelfoogst'}>inchrijvingsformulier</Link>. Van zodra je
        inschrijving is verwerkt, kan je komen oogsten!</p>
    </Content>
    <Questions>
      <h3>Zit je nog met vragen?</h3>
      <p>Aarzel niet om ons te <a href={'mailto:info@groentegeweld.be'}>contacteren</a>.</p>
    </Questions>
    <Footer/>
  </div>;
};

export default SelfHarvestPage;
