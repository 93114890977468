import React from 'react';
import styled from "styled-components";
import { Button } from "primereact/button";
import { formulas } from "../shared/config.formulas";
import { compact, last, values } from 'lodash';
import { useNavigate } from "react-router-dom";
import { SubscriptionSubType } from 'shared/types/types';
import { useQuery } from "@apollo/react-hooks";
import { ProductByCodeQuery } from "../pages/SubscriptionWizard/SubscriptionWizard.gql";
import { ProductByCode } from "../__generated__/types";
import { formatCurrency } from "../../src/shared/utils/currency.utils";
import { vat } from "../../src/shared/utils/vat.utils";

const Formulas = styled.div<{ columns: number }>`
  display: grid;
  grid-gap: 16px;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(${({columns}) => columns}, 1fr);
  }
`;

const Formula = styled.div`
  border: 1px solid var(--green);

  display: grid;
  grid-template-rows: max-content 1fr max-content;

  .title {
    background-color: var(--green);
    padding: 4px;
    text-align: center;

    h2 {
      margin-bottom: 0;
      color: white;
      text-transform: uppercase;
    }

    h3 {
      color: white;
      font-weight: normal;
    }
  }


  &.highlight {

    border-width: 2px;
    border-color: var(--orange);

    .title {
      background-color: var(--orange);

      h2 {
        font-weight: bold;
      }
    }
  }
`;


const Description = styled.div`
  padding: 8px;

`;

const Pricing = styled.div<{ options: number }>`
  padding: 8px 32px;

  h3 {
    text-align: center;
  }

  .prices {
    display: grid;
    grid-template-columns: repeat(${props => props.options}, 1fr);
    grid-gap: 8px;

    > div {

      span.name {
        color: var(--green);
      }

      text-align: center;
      display: grid;
      grid-template-rows: max-content max-content;

      span.name,
      span.price {
        font-size: 1.1em;
        font-weight: bold;
      }

      span.price {
        font-size: 1.3em;
      }
    }
  }

  .highlight > & {
    .prices > div {
      span.name {
        color: var(--orange);
      }
    }
  }
`;

const Order = styled.div`
  padding: 16px 16px 0 16px;
  display: grid;
  grid-template-rows: max-content max-content;
  text-align: center;

  .p-button.p-button-outlined {
    color: var(--orange);
    font-weight: bold;
  }

  .p-button.p-button-link {
    color: var(--surface-60);
    font-size: 0.9em;
  }
`;

const SelfHarvestFormula = () => {
  const {data} = useQuery<ProductByCode>(ProductByCodeQuery, {variables: {code: 'zelfoogst-2023'}});
  const navigate = useNavigate();
  let filteredFormulas = values(formulas)
    .filter(f => [SubscriptionSubType.SELF_HARVEST].includes(f.membershipSubType));
  return <div>
    <Formulas columns={filteredFormulas.length}>
      {filteredFormulas
        .map(formula => {
          let product = data?.product;
          let priceExcl = last(compact(product?.productPrices))?.value;
          let price = (product && priceExcl) && priceExcl * (1 + vat(product.vat));
          return (
            <Formula
              className={'highlight'}>
              <div className='title'>
                <h2>{formula.name}</h2>
                <h3>{formula.shortDescription}</h3>
              </div>

              <Description>
                <ul>
                  {formula.description?.map((desc, idx) => <li key={`desc${idx}`}>{desc}</li>)}
                </ul>
              </Description>
              <Pricing options={formula.pricing.length}>
                <h3>{formula.pricing.length === 1 ? 'Tarief' : 'Tarieven'}</h3>
                <div className="prices">

                  {formula.pricing.map(pricing => <div>
                    {formula.pricing.length > 1 && <span className={'name'}>{pricing.title}</span>}
                    <span className={'price'}>{price && formatCurrency(price, 0)},- per persoon</span>
                  </div>)}
                </div>
              </Pricing>
              <Order>
                <Button
                  label={'Word nu zelfoogster'}
                  className="p-button-outlined"
                  onClick={() => {
                    window.location.href = `https://www.mijnboer.be/groentegeweld/inschrijven/zelfoogst`;
                  }}
                />
                <Button
                  label={'probeer 8 weken'}
                  className="p-button-link"
                  onClick={() => {
                    window.location.href = `https://www.mijnboer.be/groentegeweld/inschrijven/zelfoogst`;
                  }}
                />
              </Order>
            </Formula>
          );
        })}
    </Formulas>

  </div>;
};

export default SelfHarvestFormula;
