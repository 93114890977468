import gql from "graphql-tag";

export const ClientFieldsFragment = gql`
fragment ClientFields on Client {
  id
  name
  firstName
  dateOfBirth
  street
  streetNumber
  city
  cityPostalCode
  companyName
  email
  secondaryEmail
  newsletter
  whatsApp
  phone
  type
  billingEmail
  vatNumber
  remarks
  group { id }
  ibanNumbers
  bankTransactions {
    id
    amount
  }
  auth0userId
  customerInfo {
    members {
      id
      name
      firstName
      dateOfBirth
    }
  }
}
`;

export const ClientUpdate_CustomerInfo_MembersMutation = gql`
  mutation ClientUpdate_CustomerInfo_Members($id: ID!, $membersUpdate: MemberUpdateManyWithoutCustomerInfoInput!) {
	updateClient(
    where: {id: $id}
    data: {
      customerInfo: {
        update: {
          members: $membersUpdate
        }
      }
    }
  ){
    id
    customerInfo {
      members {
        id
        name
        firstName
        dateOfBirth
      }
    }
  }
}

`;

export const ClientsQuery = gql`
${ClientFieldsFragment}
query Clients {
  clients {
    ...ClientFields
  }
}`;

export const ClientQuery = gql`
${ClientFieldsFragment}
query Client($id: ID!) {
  client(where: {id: $id}) {
    ...ClientFields
  }
}
`;

export const ClientIdByEmailQuery = gql`
${ClientFieldsFragment}
query ClientIdByEmail($email: String!) {
  clients(where: {email: $email, farm: {code: "groentegeweld"}}) {
    id
  }
}
`;

export const ClientByEmailQuery = gql`
${ClientFieldsFragment}
query ClientByEmail($email: String!) {
  clients(where: {email: $email, farm: {code: "groentegeweld"}}) {
    ...ClientFields
    clientOrders(
      orderBy: createdAt_DESC
    	first: 1
      where: {
        productOrders_some: {
          product: {
            category: {
              code_in: ["zelfoogst", "pakketten", "leeggoed"]
            }
          }
        }
      }
    ) {
      extraProductOrders:  productOrders(where: {
        ambassadorDiscount: false,
        product: {
        	type: SUBSCRIPTION,
        	category: {code_in: ["bloemen", "uien", "eieren", "aardappelen"]
        }
        }})
      {
        createdAt
        frequency
        deliveries(orderBy: plannedDeliveryDate_DESC first: 1) {
          plannedDeliveryDate
          deliveryLocation
        }
        product {
          id
          name
          code
          category {
            code
          }
        }
      }
      basketProductOrders: productOrders(where: {product: {category: {code: "pakketten"}}}) {
        createdAt
        deliveries(orderBy: plannedDeliveryDate_DESC first: 1) {
          plannedDeliveryDate
          deliveryLocation
        }
        product {
          name
          code
          category {
            code
          }
        }
      }
      selfHarvestProductOrders: productOrders(where: {product: {category: {code: "zelfoogst"}}}) {
        createdAt
        product {
          name
          code
          category {
            code
          }
        }
      }
      boxProductOrders: productOrders(where: {product: {category: {code: "leeggoed"}}}) {
        quantity
        product {
          name
          code
          category {
            code
          }
        }
      }
    }
  }
}
`;

export const ClientByAuth0UserIdQuery = gql`
${ClientFieldsFragment}
query ClientByAuth0UserId($auth0userId: String!, $auth0Email: String!, $today:DateTime!) {
  clientsByUserId: clients(where: {auth0userId: $auth0userId}) {
    ...ClientFields
    createdAt
    clientOrders(where: {
      deliveryEndDate_gte: $today
    }, orderBy: deliveryEndDate_ASC) {
      id
      deliveryDate
      deliveryEndDate
      productOrders {
        id
        product {
          id
          code
          name
          category {
            code
          }
        }
      }
    }
  }

  user(where: {email: $auth0Email}) {
    id
  }
}
`;

export const ClientUpdateMutation = gql`
mutation UpdateClient($id: ID!, $data: ClientUpdateInput!) {
  updateClient(
    where: {id: $id}
    data: $data
  ) {
    id
  }
}
`;

export const ClientUpsertMutation = gql`
mutation UpsertClient($id: ID, $createData: ClientCreateInput!, $updateData: ClientUpdateInput!) {
 	upsertClient(
    	where: {id: $id}
    	create:$createData
    	update: $updateData
  ) {
    id
  }
}
`;

export const ClientsForMailChimpQuery = gql`
${ClientFieldsFragment}
query ClientsForMailChimp {
  clients {
    ...ClientFields
    clientOrders(
      where: {
        productOrders_some: {product: {
          OR: [
            {code_starts_with: "pakketten-2023"},
            {code_starts_with: "zelfoogst-2023"}
          ]
        }}
      }
    ) {
      deliveryEndDate
      productOrders(
        where: {product: {
          OR: [
            {code_starts_with: "pakketten-2023"},
            {code_starts_with: "zelfoogst-2023"}
          ]
        }}
      ) {
      	product { code category { code }}
      	deliveries(
          orderBy: plannedDeliveryDate_ASC

        ) {
          deliveryLocation
          plannedDeliveryDate
          deliveryDate
        }
      }
    }
    group {
      code
    }
  }
}`;

