import gql from "graphql-tag";

export const HarvestListQuery = gql`
query HarvestListData {
  fields(orderBy: orderNumber_ASC) {
    id
    name
    orderNumber
    rotationAreas(orderBy: number_ASC) {
      id
      number
      plots(orderBy: number_ASC) {
      	id
      	number
        cultivations(
          where: {
            events_some: {
              type: HARVEST
            }
          }
        ) {
          events {
            date
            metadata
          }
          crop {
            name
          }
          varieties {
            variety {
              alternativeCropName
            }
          }
        }
      }
    }
  }
}
`;
