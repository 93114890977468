import React, { FunctionComponent } from 'react';
import 'antd/dist/antd.css';
import styled from "styled-components";
import { Content, Parallax } from "../../App";
import { Link, useNavigate } from 'react-router-dom';
import Footer from "../../components/Footer";
import { Button } from "primereact/button";

const Main = styled.div`
  padding: 1vh 1vw;
`;

export const Grid = styled.div<{ columns: string, width?: number }>`
  display: grid;
  grid-gap: 3vh 2vw;
  align-items: start;

  @media only screen and (min-width: ${props => `${props.columns.split(' ').length * (props.width ||300)}px`}) {
    grid-template-columns: ${props => props.columns};
  }

  > div {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

const FormulaContainer = styled.div<{ backgroundColor: string, frontColor: string }>`
  background-color: ${({backgroundColor}) => backgroundColor};
  margin: 0 0;

  p {
    color: white;
  }

  h2 {
    color: ${({frontColor}) => frontColor};
  }

  a {
    text-align: right;
    background: ${({frontColor}) => frontColor};
    padding: 4px 16px;
    color: white;
    font-weight: normal;
    font-size: 110%;
  }

  div > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .p-button-outlined {
    background-color: transparent;
    color: white;
    border: 1px solid white;

    &.p-button-green {
      &:enabled:hover {
        background-color: var(--green);
      }
    }
  }
`;

const Home: FunctionComponent = () => {

  const navigate = useNavigate();

  return <div>
    <p style={{
      fontStyle: "italic",
      fontSize: '1.1em',
      padding: '16px',
      display: "block",
      background: "var(--green-20)",
      fontWeight: "bold",
      margin: '0 auto',
      textAlign: 'center',
    }}>Op Groentegeweld gaan we <span style={{fontWeight: 'bold', color: 'var(--green)'}}>biologisch én ecologisch</span> aan de slag met onze bodem en kiezen we de aangepaste
      rassen, <span style={{fontWeight: 'bold', color: 'var(--orange)'}}>beoordeeld op smaak</span>! </p>
    <Content>
      <Main>
        <div>
          <Grid columns={'4fr 4fr'}>
            <div>
              <h1><span>Ons</span> verhaal</h1>
              <p>
                Groentegeweld is een <strong>kleinschalig bio-ecologisch</strong> tuinbouwbedrijf. We telen groenten en
                sindskort ook (klein-)fruit.
                Is echt ecologische landbouw op mensenmaat mogelijk? Dat is de uitdaging die Willem en Dirk
                aangaan!
              </p>

              <h2>Hoe geniet jij hier mee van?</h2>
              <p>
                Je kan bij ons <Link to={'/zelfoogst'}>zelfoogster</Link> worden of een <Link
                to={'/pakketten'}>groentepakket</Link> afnemen. Je bent bij ons
                niet zomaar een klant, maar een &nbsp;
                <Link to={'/csa'}>(oogst)aandeelhouder</Link>! Met dat aandeel zorg je
                voor
                heerlijke, seizoensgebonden groenten op je bord. Want echte, pure smaken daar draait het bij ons om. Zo
                zaaien we de lekkerste wortelen en niet per se de rechtste zoals meestal gebeurt. Zo groeien onze
                tomaten
                in volle grond en rijpen ze bij echt, natuurlijk zonlicht. En zo telen we meer dan 60 verschillende
                gewassen, zodat er voor ieder wat wils is.
              </p>
            </div>
            <div style={{alignSelf: 'center'}}>
              <img src={'125 groentegeweld.jpg'} alt={''}/>
            </div>
          </Grid>
          <Grid columns={'1fr 1fr'} style={{marginTop: '2vh'}}>
            <div>
              <img src={'139 groentegeweld.jpg'} alt={''}/>
            </div>
            <div>
              <h2>Hoe doen we dat op het veld?</h2>
              <p><strong>De bodem</strong> is wat ons betreft <strong>‘de grond van de zaak’</strong>: we
                ploegen of frezen niet, maar laten de natuur zijn werk doen (lang leve de regenwormen!). Wist je dat we
                zelfs geen tractor hebben?! We gaan steeds op zoek naar een ecologisch evenwicht: zo plaatsen we
                nestkastjes om vogels aan te trekken want vogels eten rupsen, rupsen die anders onze kolen opeten.
              </p>
              <p>Dit is niet de makkelijkste of kortste weg om groenten te kweken, maar voor ons is dit <strong>de
                toekomst van
                landbouw</strong>: economisch en ecologisch, landbouw en natuur, kleinschalig maar efficient, <strong>dicht
                bij de klant</strong>!
              </p>
            </div>
          </Grid>
        </div>
      </Main>
    </Content>
    <div>
      <Parallax img={'zonnebloem.jpg'} height={'150px'}/>

      <FormulaContainer backgroundColor={'var(--green-95)'} frontColor={'var(--orange)'}>
        {/*<Parallax img={'zonnebloem.jpg'} height={'100%'} style={{position: 'absolute', zIndex: -1,}}/>*/}
        <Content>
          <Grid columns={'1fr 1fr 1fr'}>
            <div>
              <img src={'IMG_7041.jpg'} alt={''}/>
            </div>
            <div>
              <h2>Zelfoogst</h2>
              <p>Hou je van ontdekken wat er in onze ‘openlucht’ groentewinkel te vinden valt en geniet je van rust en
                van frisse lucht opsnuiven? Dan ben je een geboren zelfoogster.</p>
              <div style={{textAlign: 'right'}}>
                <Button
                  label={'Ontdek onze zelfoogst'}
                  icon={'pi pi-arrow-right'}
                  iconPos={'right'}
                  className={'p-button-outlined p-button-orange'}
                  onClick={() => navigate('/zelfoogst')}
                />
              </div>
            </div>
            <div>
              <img src={'IMG_6104.jpg'} alt={''}/>
            </div>
          </Grid>
        </Content>
      </FormulaContainer>
      {/*<Parallax img={'zonnebloem.jpg'} height={'100%'} style={{position: 'absolute', zIndex: -1}}/>*/}
      {/*<div style={{height: '4vh'}}></div>*/}
      {/*<Parallax img={'zonnebloem.jpg'} height={'200px'}/>*/}
      <Parallax img={'zonnebloem.jpg'} height={'150px'}/>
      <FormulaContainer backgroundColor={'var(--orange-95)'}
                        frontColor={'var(--green)'}
      >
        {/*<Parallax img={'zonnebloem.jpg'} height={'100%'} style={{position: 'absolute', zIndex: -1}}/>*/}
        <Content>
          <Grid columns={'1fr 1fr 1fr'}>
            <div>
              <img src={'IMG_7906.jpg'} alt={''}/>
            </div>
            <div>
              <h2>Pakketten</h2>
              <p>Als pakketdeelnemer krijg je onze verse, biologische groenten aangeboden in een <strong>handige
                herbruikbare
                box</strong>. De boeren vullen (twee)wekelijks jouw pakket met een, <strong>al dan niet
                zelfgekozen</strong>, variatie van het
                veld.
                Uiteraard volgen we helemaal <strong>de seizoenen</strong>: geen tomaten in april, des te meer in
                augustus! We leveren
                heel het jaar door pakketten. Je kan zelf kiezen voor een periode van 30 of 40 pakketten.</p>
              <div style={{textAlign: 'right'}}>
                <Button
                  label={'Ontdek onze pakketten'}
                  icon={'pi pi-arrow-right'}
                  iconPos={'right'}
                  className={'p-button-outlined p-button-green'}
                  onClick={() => navigate('/pakketten')}
                />
              </div>
            </div>
            <div>
              <img src={'IMG_5657.jpg'} alt={''}/>
            </div>
          </Grid>
        </Content>
      </FormulaContainer>
      <Parallax img={'zonnebloem.jpg'} height={'150px'}/>
    </div>

    <Footer/>
  </div>;
};

export default Home;
