import React, { useEffect } from 'react';
import { Checkbox } from "primereact/checkbox";
import { useField } from "formik";
import styled from "styled-components";
import classNames from "classnames";

interface FormCheckboxProps {
  name?: string;
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  label: string;
  extra?: any;
  small?: boolean;
}

const Container = styled.div<{ small?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 4px;

  label {
    display: block;

    &.p-invalid {
      color: #a80000;
    }
  ;
  }

  ${props => props.small && `
    font-size: 90%;
    grid-gap: 2px;
    align-items: center;

    .p-checkbox ,
    .p-checkbox .p-checkbox-box {
      width: 20px;
      height: 20px;
    }
  `}
  .p-checkbox.p-invalid > .p-checkbox-box {
    border-width: 2px;
  }
`;

const FormCheckbox = (props: FormCheckboxProps) => {
  const [field, meta, helper] = useField(props.name || '');

  const isFormFieldValid = () => props.name && !!(meta.touched && meta.error);
  const getFormErrorMessage = () => {
    return isFormFieldValid() && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    if (props.name) {
      helper.setValue(props.value);
    }
  }, [props.value, props.name]);

  return <Container small={props.small}>
    <Checkbox
      {...field}
      className={classNames({'p-invalid': (props.name && meta.touched && meta.error)})}
      disabled={props.disabled}
      inputId={`${props.name}Checkbox`}
      name={props.name}
      checked={props.value}
      onChange={e => {
        helper.setValue(e.checked);
        props.onChange(e.checked || false);
      }}
    />
    <div>
      <label
        className={classNames({'p-invalid': (props.name && meta.touched && meta.error)})}
        htmlFor={`${props.name}Checkbox`}
      >
        {props.label}
      </label>
      {getFormErrorMessage()}
      {props.extra && props.extra()}
    </div>
  </Container>;
};

export default FormCheckbox;
