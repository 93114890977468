import React, { createContext, ReactNode, useContext, useState } from "react";
import { WizardStep } from "./SFSteps";

interface FormWizardContextValue {
  steps: WizardStep[],
  goToPrevStep: () => void,
  goToNextStep: () => void,
  goToStep: (stepIdx: number) => void,
  activeStepIdx: number,
}

export const DEFAULT_VALUE: FormWizardContextValue = {
  activeStepIdx: 0,
  steps: [],
  goToPrevStep: () => {
  },
  goToNextStep: () => {
  },
  goToStep: () => {
  },
};

export type FormWizardProviderProps = {
  children: ReactNode;
  formWizardContextValue?: FormWizardContextValue;
  steps: WizardStep[];
};

const FormWizardProvider = (props: FormWizardProviderProps) => {
  const [contextValue, setContextValue] = useState<FormWizardContextValue>(props.formWizardContextValue || DEFAULT_VALUE);

  const goToStep = (stepIdx: number) => {
    setContextValue(value => {
      return ({...value, activeStepIdx: stepIdx});
    });
  };
  const goToNextStep = () => {
    setContextValue(value => {

      let activeStepIdx = value.activeStepIdx + 1;
      return ({...value, activeStepIdx: activeStepIdx});
    });
  };
  const goToPrevStep = () => {
    setContextValue(value => {
      let activeStepIdx = value.activeStepIdx - 1;
      return ({...value, activeStepIdx: activeStepIdx});
    });
  };

  return <FormWizardContext.Provider value={{
    ...contextValue,
    steps: props.steps,
    goToPrevStep,
    goToStep,
    goToNextStep,
  }}>
    {props.children}
  </FormWizardContext.Provider>
};

const FormWizardContext = createContext(DEFAULT_VALUE);

const useFormWizard = () => useContext(FormWizardContext);

export { FormWizardProvider, useFormWizard };
