import React from 'react';
import { Content, Parallax } from "../../App";
import { compact, sortBy } from 'lodash';
import styled from "styled-components";
import { formulas } from "../../shared/config.formulas";
import FormulaOption from "./components/FormulaOption";
import { Grid } from "../home";
import DeliveryLocations from "../SubscriptionWizard/components/FormulaStep/components/DeliveryLocations";
import Footer from "../../components/Footer";
import CallToAction from "./components/CallToAction";
import { SubscriptionSubType } from "../../shared/types/types";
import ReactGA from "react-ga4";

export const FormulaComparator = styled.div<{ items: number }>`
  display: grid;
  grid-gap: 8px;

  // @media only screen and (min-width: 600px) {
  //   grid-template-columns:  1fr 1fr;
  //
  // }
  //
  // @media only screen and (min-width: 1150px) {
    //   grid-template-columns: ${props => `repeat(${props.items > 3 ? 2 : props.items}, 1fr)`};
  // }
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));

  &.p-invalid {
    border: 1px solid var(--invalid);
  }
`;

export const Questions = styled.div`
  padding: 32px;
  background-color: var(--green-40);
  text-align: center;

  @media only screen and (min-width: 600px) {
    padding: 8px 32px;
  }

  h3, p {
    margin: 0;
  }
`;

const BasketsPage = () => {
  return <div>
    <Content>
      <h1>Pakketten</h1>

      <Grid columns={'2fr 1fr'}>
        <div>
          <p>
            <strong>Onze pure smaak</strong>, recht van het veld en <strong>jouw kookkunsten</strong>: dat zorgt
            voor <strong>eerlijke en heerlijke gerechten</strong> op
            tafel.</p>
          <p>Daar draait het om bij Groentegeweld. <br/>Wij stellen <strong>wekelijks een divers pakket</strong> samen,
            maar je kan het <strong> aanpassen naar jouw behoeften</strong>.
            <span className="font-italic">
            <br/>Veel tijd om te koken, stop er wat <strong>extra</strong> in.
            <br/>Geen fan van aubergine? <strong>Vervang </strong>ze door wat extra tomaten.
            <br/>Een weekje op reis? Dan <strong>verplaats</strong> je gewoon je pakket.
              <br/>...
              </span>
            <br/>Kortom het flex-abonnement van Groentegeweld: <br/><strong>makkelijk, flexibel, divers en gewoonweg erg
              lekker</strong>.
          </p>
        </div>
        <div>
          <img src={'IMG_5652.jpg'} alt={'Pakketten'}/>
        </div>
      </Grid>
      <h2>Klein of groot?</h2>
      <div>
        <p><strong>Klein pakket </strong>(+/- 1-2 personen): 3 &agrave; 4 maaltijden. <br/>
          <strong>Groot pakket</strong> (gezinspakket): grotere porties, extra groente(n) <br/>
          <span style={{fontStyle: "italic", fontSize: '0.9em'}}>Welk pakket voor jou geschikt is, hangt echter af van je gezinssamenstelling en van je eetgewoonten.</span>
        </p>
      </div>
      <h2>Engagement wordt beloond</h2>
      <div>
        <p>Groentegeweld staat voor <strong>seizoensgebonden eten</strong>, <strong>het hele jaar door</strong>. Daarom
          zijn onze <strong>langdurige abonnementen</strong> per pakket <strong>goedkoper</strong>. Door jouw <strong>groter
            engagement</strong> ervaar je de seizoenen veel beter en
          geef je de boer voldoende zekerheid!</p>
      </div>

      <h1 id={'afhaalpunten'}>Altijd een <span>afhaalpunt</span> in je buurt</h1>
      <p>Onze pakketten worden op <strong>vrijdag</strong> (namiddag) geleverd in het afhaalpunt. </p>
      <div style={{marginTop: "32px"}}>
        <DeliveryLocations static={true} hideAddress/>

      </div>


    </Content>
    <Parallax
      img={'formule-pakket2.jpg'}
      minHeight={'150px'}
      height={'150px'}
    />
    <Content>
      <FormulaOption
        periods={
          sortBy(compact(formulas.BASKETS_FLEX.periods), 'order')
            .filter(p => !p.hidden)
            .filter(p => p.codeSmall && p.codeSmall.indexOf('trial') === -1)
        }
        trial={sortBy(formulas.BASKETS_FLEX.periods)
          .find(p => p.codeSmall && p.codeSmall.indexOf('trial') === -1)}
        type={'flex'}
      >
        <h1 className={'main'}>Flexibele <span>pakketten</span></h1>

        <p>Een flex-pakket betekent <strong>meer flexibiliteit</strong>. Zo kan je <strong>zelf je pakket
          samenstellen</strong> door de groenten en porties te kiezen op onze
          website. <br/>
          Vergat je je keuze door te geven? Dan stellen wij een pakket voor je samen.
          <br/>
          Wil je een keertje een extra groot pakket? Dan selecteer je meer porties. Je bent ook <strong>flexibel
            in tijd</strong>:
          zo kan je een <strong>pakket overslaan</strong> als je op vakantie gaat.</p>
      </FormulaOption>
    </Content>
    <CallToAction type={'pakketten'} label={'Schrijf mij in voor een flex-pakket'}/>
    <Parallax
      img={'IMG_5858.jpeg'}
      minHeight={'180px'}
      height={'150px'}
    />

    <Content>

      <Grid columns={'1fr 2fr'} style={{alignItems: "center"}}>
        <div>
          <img alt={'extras'} src={'potato-onion.png'} style={{mixBlendMode: "multiply"}}/>
        </div>

        <div>
          <h1>En er is meer...</h1>
          <p>
            Basisproducten zoals <strong>eieren, aardappelen of uien</strong> kan je ook bij ons bestellen. We
            produceren ze meestal niet op eigen veld, we laten dit over aan bevriende bioboeren uit de buurt. Door ook
            dit in je pakket te steken, maak je opnieuw voor heel wat producten de keten korter. <br/>Bovendien heb je
            deze basisproducten ook steeds in huis. Je kan uiteraard de hoeveelheden aanpassen naar je gezinssituatie!
          </p>
        </div>
      </Grid>
    </Content>
    <Parallax
      img={'IMG_7519.jpg'}
      mobileImg={'IMG_7519.jpg'}
      minHeight={'300px'}
      height={'300px'}
    />
    <Questions>
      <h3>Zit je nog met vragen?</h3>
      <p>Aarzel niet om ons te <a href={'mailto:info@groentegeweld.be'}>contacteren</a>.</p>
    </Questions>

    <Footer/>
  </div>;
};

export default BasketsPage;
