import configJson from "./auth_config.json";
import moment from "moment/moment";

export const CURRENT_SEASON = "2023-2024";
export const POINTS_PRICE_INCL = 0.6;


export function getAuthConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.

  const audience =
    configJson.audience && configJson.audience !== "mn3nmVcYiM294636k1hiOjEcJV2qd3z8"
      ? configJson.audience
      : null;

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    ...(audience ? { audience } : null),
  };
}

//export const TODAY = appConfig.environment ? momentFromDdMmYyyy("09-05-2023") : moment();
// export const TODAY = momentFromDdMmYyyy("02-09-2023");
export const TODAY = moment();
