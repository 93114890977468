import React, { useEffect } from 'react';
import styled from "styled-components";
import { formatCurrencySign } from "../../../../../src/shared/utils/currency.utils";
import { useShop } from "../../Shop.context";
import { useField } from "formik";
import classNames from "classnames";

interface PackagingProps {

}

const Options = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 16px;

  padding: 4px;

  &.p-invalid {
    border: 1px solid var(--invalid);
  }

`;

const Option = styled.div`
  border: 1px solid var(--green);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  cursor: pointer;

  h4 {
    font-size: 150%;
  }

  &:hover, &.selected {
    opacity: 1;
    background-color: var(--green-40);
    border-width: 2px;
  }

  .price {
    font-size: 150%;
    font-weight: bold;
    margin-top: 16px;
  }
`;

export const PACKAGING_OPTIONS = [
  {title: 'Kartonnen zak', description: 'Makkelijk en snel, wie weet hergebruik ik hem wel.', value: true, price: 1.5},
  {title: 'Eigen tas', description: 'Ik gebruik een eigen draagtas en vermijd zo mee afval.', value: false, price:0},
];

const Packaging = (props: PackagingProps) => {
  const {setPersonalField, shopDetails: {packaging}} = useShop();
  let field = useField('packaging');
  const meta = field[1];
  const helper = field[2];

  const isFormFieldValid = () => !!((meta.touched) && meta.error);

  useEffect(() => {
    helper.setValue(packaging);
  }, [packaging]);

  return <>
    <h3 className={classNames({'p-invalid': (meta.touched && meta.error)})}>Verpakking</h3>
    <div>
      {isFormFieldValid() && <small className={'p-error'}>{meta.error}</small>}
      <Options className={classNames({'p-invalid': isFormFieldValid()})}>
        {PACKAGING_OPTIONS.map(packagingOption => <Option
          onClick={() => {
            setPersonalField('packaging', packagingOption.value);
            helper.setValue(packagingOption.value);
          }}
          className={classNames({selected: packaging === packagingOption.value})}
        >
          <h4>{packagingOption.title}</h4>
          <p>{packagingOption.description}</p>
          <div className="price">
            {packagingOption.price > 0 ? `+ ${formatCurrencySign(1.50)}` : 'Gratis'}
          </div>
        </Option>)}
        {/*<Option*/}
        {/*  onClick={() => {*/}
        {/*    setPersonalField('packaging', packagingOption.value);*/}
        {/*    helper.setValue(packagingOption.value);*/}
        {/*  }}*/}
        {/*  className={classNames({selected: packaging === packagingOption.value})}*/}
        {/*>*/}
        {/*  <h4>Kartonnen zak</h4>*/}
        {/*  <p></p>*/}
        {/*  <div className="price">+ {formatCurrencySign(1.50)}</div>*/}
        {/*</Option>*/}
        {/*<Option>*/}
        {/*  <h4>Eigen tas</h4>*/}
        {/*  <p>Ik gebruik een eigen draagtas en vermijd zo mee afval.</p>*/}
        {/*  <div className="price">Gratis</div>*/}
        {/*</Option>*/}
      </Options>
    </div>
  </>;
};

export default Packaging;
