import moment, { Moment } from 'moment';

export const startYearForSeason = (seasonString: string) => moment(seasonString.substr(0, 4), 'YYYY');
export const endYearForSeason = (seasonString: string) => moment(seasonString.substr(5, 4), 'YYYY');

export const startOfSeason = (seasonString: string, today?: Moment): Moment => {
  let year = startYearForSeason(seasonString);
  const start = year.month(4).startOf('month');
  if (!today || start.isAfter(today)) {
    return start;
  } else {
    return today.endOf('isoWeek').add(1, 'day');
  }
};

export const januaryFirstBeforeSeason = (seasonString: string): Moment => {
  return startOfSeason(seasonString).startOf('y');
};

export const endOfSeason = (seasonString: string): Moment => {
  return startOfSeason(seasonString).add('12', 'month').subtract(1, 'day');
};

export const firstBasketDeliveryOfSeason = (seasonString: string): Moment => {
  let seasonStart = startOfSeason(seasonString);
  if (seasonStart.isoWeekday() === 1) {
    return seasonStart;
  } else {
    return seasonStart.endOf('isoWeek').add(1, 'day');
  }
};

export const firstSelfHarvestDayOfSeason = (seasonString: string): Moment => {
  let seasonStart = startYearForSeason(seasonString);
  return seasonStart.month(4).day(0);
};

export const firstWinterBasketDeliveryOfSeason = (seasonString: string): Moment => {
  let seasonEndYear = endYearForSeason(seasonString);
  let startOfYear = seasonEndYear.clone().startOf('year');
  if (startOfYear.isoWeekday() === 1) {
    return startOfYear;
  } else {
    return startOfYear.add(2,'w').startOf('isoWeek');
  }
};

export const nextBasketDeliveryOfSeason = (seasonString: string, date: Moment): Moment => {
  const firstBasketDelivery = firstBasketDeliveryOfSeason(seasonString);
  if (firstBasketDelivery.isAfter(date)) {
    return firstBasketDelivery
  } else {
    if (firstBasketDelivery.isSame(date, 'd')) {
      return date.clone();
    } else {
      return date.clone().endOf('isoWeek').add(1, 'day');
    }
  }
};

/**
 * @deprecated This doesn't take winter/year basket into account
 * */
export const remainingBasketsInSeason = (seasonString: string, today: Moment): number => {
  const nextBasketDelivery = nextBasketDeliveryOfSeason(seasonString, today);
  const lastBasketDelivery = lastPlannedBasketDelivery(seasonString);
  return lastBasketDelivery.diff(nextBasketDelivery, 'w') + 1;
};

export const remainingBasketsInSeasonBasedOnStartDate = (seasonString: string, startDate: Moment): number => {
  const nextBasketDelivery = nextBasketDeliveryOfSeason(seasonString, startDate);
  const lastBasketDelivery = lastPlannedBasketDelivery(seasonString);
  return lastBasketDelivery.diff(nextBasketDelivery, 'w') + 1;
};

export const remainingWeeksInSelfHarvestSeason = (seasonString: string, today: Moment): number => {
  const nextBasketDelivery = startOfSeason(seasonString, today);
  const lastBasketDelivery = endOfSeason(seasonString);
  return lastBasketDelivery.diff(nextBasketDelivery, 'w') + 1;
};

export const lastTrialBasketDelivery = (firstDay: Moment): Moment => {
  // week 1 is the firstday
  return firstDay.clone().add(3, 'week');
};

export const lastPlannedBasketDelivery = (season: string): Moment => {
  // week 1 is the firstday
  return firstBasketDeliveryOfSeason(season).clone().add(31, 'week');
};
