import React, { useEffect } from 'react';
import { useField } from "formik";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";

interface LocationDropdownProps {
  name: string;
  value: string | undefined;
  placeholder: string;
  onChange: (value: string | undefined) => void;
  options: SelectItem[];
}

const LocationDropdown = (props: LocationDropdownProps) => {
  const [field, meta, helper] = useField(props.name);

  const isFormFieldValid = () => !!(meta.touched && meta.error);
  const getFormErrorMessage = () => {
    return isFormFieldValid() && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    helper.setValue(props.value);
  }, [props.value]);

  return <div className="w-full flex flex-column"><Dropdown
    {...field}
    // style={{maxWidth: '310px'}}
    placeholder={props.placeholder}
    value={props.value}
    options={props.options}
    showClear
    onChange={e => {
      helper.setValue(e.value);
      props.onChange(e.value);
    }}
  />
    <div>{getFormErrorMessage()}</div>
  </div>;
};

export default LocationDropdown;
