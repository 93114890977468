import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useLazyQuery } from "@apollo/react-hooks";
import { FindClientForShop, GetProducts_products } from "../../__generated__/types";
import { StringParam, useQueryParam } from "use-query-params";
import { FindClientForShopQuery } from "./Shop.gql";
import SFSteps from "../../shared/components/Wizard/SFSteps";
import { FormWizardProvider, useFormWizard } from "../../shared/components/Wizard/WizardContext";
import { Button } from "primereact/button";
import { WizardActions } from "../../shared/components/Wizard/WizardComponents";
import { Form, Formik, FormikHelpers } from "formik";
import { validationSchema } from "./Shop.validationSchema";
import { DEFAULT_VALUE, ShopDetails, ShopProvider, useShop } from "./Shop.context";
import ProductsStep from "./components/ProductsStep";
import PersonalStep from "./components/PersonalStep";
import OverviewStep from "./components/OverviewStep";
import { Link } from "react-router-dom";
import { compact, first, flatten, map } from "lodash";
import { findAmbassadorDiscountProductOrderForProduct, findAmbassadorProductOrder } from "../../shared/utils/productOrder.utils";
import { ambassadorDiscountPercentage, ambassadorOptionFromProductCode } from "../../shared/utils/ambassador.utils";

const Container = styled.div`
  //display: grid;
  //grid-template-columns: 1fr;
  //grid-gap: 1em;
  margin: 1em auto;
  max-width: 1300px;

  @media only screen and (max-width: 1300px) {
    margin: 1em;
  }
`;


export const Content = styled.div`
  padding: 1vh 0;
  @media only screen and (min-width: 1000px) {
    max-width: 1300px;
    margin: 0 auto;
  }
`;

export interface ShopProduct extends GetProducts_products {
  promoPrice?: number;
}

//Dirty fix: -120, otherwise it title scrolls underneath site header
export const scrollToRef = (ref: any) => window.scrollTo(0, ref.current && ref.current.offsetTop - 120);

const Shop = () => {
    const wizardHeader = useRef(null);
    const [emailParam] = useQueryParam('email', StringParam);

    const [findClient, {data: clientData}] = useLazyQuery<FindClientForShop>(FindClientForShopQuery);

    const {activeStepIdx, steps, goToStep, goToNextStep, goToPrevStep} = useFormWizard();
    const {submitShopOrder, submitted, updateClientData, shopDetails, updateAmbassadorship, setPersonalField} = useShop();

    const [formikValues, setFormikValues] = useState<ShopDetails>(DEFAULT_VALUE.shopDetails);

    useEffect(() => {
      if (emailParam) {
        findClient({
          variables: {
            email: emailParam.toLowerCase().trim()
          }
        });
      }
    }, [emailParam]);

    useEffect(() => {
      if (submitted) {
        goToStep(0);
      }
    }, [submitted]);

    useEffect(() => {
      let matchingClient = first(clientData?.clients);
      //let shopClient = matchingClient?.shopClient;
      if (matchingClient ) {
        updateClientData([{
          email: matchingClient.email,
          name: matchingClient.name,
          firstName: matchingClient.firstName,
          phone: matchingClient.phone || '',
          id: 'new',
          newsletter: false,
        }]);
        setPersonalField('clientEmail', matchingClient.email);
      }
      if (matchingClient) {
        updateClientData([matchingClient]);
      }

      let ambassadorOption = undefined;
      let ambassadorProductOrder = findAmbassadorProductOrder(compact(flatten(map(matchingClient?.clientOrders, 'productOrders'))));
      if (ambassadorProductOrder?.product?.code) {
        ambassadorOption = ambassadorOptionFromProductCode(ambassadorProductOrder?.product?.code);
      }
      updateAmbassadorship(ambassadorOption || undefined);
    }, [clientData]);

    useEffect(() => {
      setFormikValues(shopDetails);
    }, [shopDetails]);

    const handleSubmit = (values: ShopDetails, actions: FormikHelpers<ShopDetails>) => {
      if (activeStepIdx === steps.length - 1) {
        submitShopOrder();
      } else {
        actions.setSubmitting(false);
        goToNextStep();
        scrollToRef(wizardHeader);
      }
    }

    return <Container>
      <Content>
        <h1 ref={wizardHeader}>Veldwinkel</h1>

        {submitted && <div>
          Bedankt voor je bestelling.<br/>
          Jouw bestelling zal klaar staan op jouw gekozen afhaalpunt op de door jouw gekozen dag.
          <br/>
          <br/>
          <Link to={'/veldwinkel'}>Ga terug naar de veldwinkel.</Link>
        </div>}

        {!submitted && <Formik
          initialValues={formikValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema[activeStepIdx]}
        >
          {({isSubmitting, isValid, submitCount, errors}) => {
            return (
              <Form id={'shopWizard'}>
                <SFSteps steps={steps} activeStepIdx={activeStepIdx} goToStep={goToStep}/>

                {activeStepIdx === 0 && <ProductsStep/>}
                {activeStepIdx === 1 && <PersonalStep/>}
                {activeStepIdx === 2 && <OverviewStep/>}

                <WizardActions>
                  <div className="left">
                    {activeStepIdx !== 0 && <Button
                      type={'button'}
                      label={"Ga terug"}
                      onClick={() => {
                        goToPrevStep();
                        scrollToRef(wizardHeader);
                      }}
                    />}
                  </div>
                  {activeStepIdx > 0 && <div className="right">
                    {!isValid && submitCount > 0 && errors &&
                      <span style={{color: '#a80000'}}>Gelieve alle verplichte velden in te vullen.</span>}
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting} iconPos={'right'}
                      type={'submit'}
                      label={activeStepIdx !== steps.length - 1 ? "Ga verder" : "Afronden"}
                    />
                  </div>}
                </WizardActions>
              </Form>
            );
          }}
        </Formik>}
      </Content>
    </Container>;
  }
;

let steps = [
  {label: 'Het veld'},
  {label: 'Jouw gegevens'},
  {label: 'Overzicht'},
];

export default () => {
  return <FormWizardProvider steps={steps}>
    <ShopProvider>
      <Shop/>
    </ShopProvider>
  </FormWizardProvider>;
};
