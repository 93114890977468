import React from 'react';
import { formatDoubleDigitStrict } from "../../../../src/shared/utils/currency.utils";
import styled from "styled-components";
import { units } from "../../../shared/utils/product.utils";
import { ShopProduct } from "../Shop";
import classNames from "classnames";

interface PriceTagProps {
  value: number;
  rejected?: boolean;
  product?: ShopProduct;
}

const Container = styled.div`
  display: grid;
  grid-template-areas: 'currency decimal fraction' 'currency decimal unit';
  grid-template-columns: max-content max-content max-content max-content;
  position: relative;

  .currency, .decimal {
    font-size: 2em;
  }


  &.promo {
    color: var(--orange);
    font-weight: bold;
  }

  &.rejected {
    //opacity: 0.4;
    font-size: 70%;
    align-self: end;
    &::after {
      position: absolute;
      content: ' ';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, var(--invalid)), color-stop(.52, transparent), color-stop(1, transparent));
      background-image: repeating-linear-gradient(163deg,transparent 0%,transparent 45%,var(--invalid) 45%, var(--orange) 53%, transparent 53%,transparent 100%);
    }
  }
`;

const PriceTag = (props: PriceTagProps) => {
  return <Container className={classNames({rejected: props.rejected})}>
    <span className={'currency'} style={{gridArea: 'currency'}}>&euro;&nbsp;</span>
    <span className={'decimal'}
          style={{gridArea: 'decimal'}}>{props.value && formatDoubleDigitStrict(props.value).slice(0, -3)}</span>
    <span className={'fraction'}
          style={{gridArea: 'fraction'}}>{props.value && formatDoubleDigitStrict(props.value).slice(-3)}</span>
    {props.product && <span className={'unit'}
           style={{gridArea: 'unit'}}>/ {props.product.amount !== 1 && props.product.amount} {props.product.unit && units[props.product.unit]}</span>}
  </Container>;
};

export default PriceTag;
