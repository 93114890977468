import gql from "graphql-tag";
import { ProductFieldsFragment } from "../../shared/queries/product.gql";

export const FindClientForShopQuery = gql`
  query FindClientForShop($email: String!) {
    clients(where: {
      email: $email
    }) {
      name
      firstName
      email
      phone
      clientOrders(
        orderBy: deliveryEndDate_DESC
        first: 1
        where: {
        productOrders_some: {
          product: {code_starts_with: "ambassadeur-"}
        }
      }) {
        deliveryEndDate
        productOrders(
          where: {
            product: {code_starts_with: "ambassadeur-"}
          }
        ) {product { code }}
      }
    }
  }
`;


// export const ShopQuery = gql`
// ${ProductFieldsFragment}
// query ShopData {
//   clientGroup(where: {
//     code: "standaard"
//   }) {
//     code
//     default
//     discount
//     id
//     name
//     order
//   }
//   products(
//     where: {
//       productAvailabilities_some: {
//         endDate: null,
//         clientGroup: {code: "standaard"}
//       }
//     },
//     orderBy: name_ASC
//   ) {
//     ...ProductFields
//     alternativeUnit
//     availableAsAlternativeUnitFor
//     avgWeight
//     minOrderQuantity
//     portionDirk
//     portionWillem
//     vat
//     productPrices(where: {endDate: null}) {
//       id
//       clientGroup { id code }
//       value
//       base
//       endDate
//       startDate
//       note
//       unit
//     }
//     discounts {
//       id
//       clientGroup { id }
//       discount
//       product { id }
//     }
//     category {
//       id
//       name
//       image
//     }
//     productPromos {
//       id
//       startDate
//       endDate
//       type
//       value
//       secondValue
//     }
//   }
// }`;

export const LinkShopClientAndMembershipMutation  = gql`
mutation LinkShopClientAndMembership($membershipId: ID!, $shopClientId: ID!) {
  updateMembership(
    where: {
      id: $membershipId,
    }
    data: {
      shopClient: {
        connect: {
          id: $shopClientId
        }
      }
    }
  ) {
    shopClient {
      id
    }
  }
}`;

export const UpsertShopOrderMutation  = gql`
  mutation UpsertShopOrder(
    $id: ID
    $shopOrderCreateInput:ShopOrderCreateInput!
    $shopOrderUpdateInput:ShopOrderUpdateInput!
  ) {
    upsertShopOrder(
      where: {
        id: $id
      }
      create: $shopOrderCreateInput
      update: $shopOrderUpdateInput
    ) {
      id
      orderDate
      deliveryDate
      deliveryLocation
      shopClient {
        id
        name
        firstName
        newsletter
        email
        phone
      }
      shopOrderItems {
        id
        amount
        priceIncl
        currentPromo {
          id
        }
        product {
          id
        }
      }
    }
  }
`;

export const FindShopClientQuery = gql`
query FindShopClient($email: String!) {
  shopClients(where: {
    email: $email
  }) {
    id
    name
    firstName
    newsletter
    email
    phone
  }

  clients(where: {
    email: $email
  }) {
    name
    firstName
    email
    phone
    clientOrders(
      orderBy: deliveryEndDate_DESC
      first: 1
      where: {
        productOrders_some: {
          product: {code_starts_with: "ambassadeur-"}
        }
      }) {
      deliveryEndDate
      productOrders(
        where: {
          product: {code_starts_with: "ambassadeur-"}
        }
      ) {product { code }}
    }
  }
}`;
