import React, { useEffect, useState } from 'react';
import { useQuery } from "@apollo/react-hooks";
import { GetProducts, GetProducts_products, GetProductsVariables, ProductStatus } from "../../__generated__/types";
import { notEmpty } from "../../shared/utils/stream.utils";
import styled from "styled-components";
import { flattenDeep, values } from 'lodash';
import { Button } from "primereact/button";
//import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { translatedUnit } from "../../shared/utils/unit.utils";
import { formatDoubleDigit } from "../../../src/shared/utils/currency.utils";
import Product from "../Shop/components/Product";
import { findProductPriceForClientGroupCode } from "../../shared/utils/product.utils";
import { GetProductsQuery } from "../../shared/queries/product.gql";

interface HorecaProps {
  clientGroup: string;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  margin: 1em auto;
  max-width: 1200px;
`;

const Products = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  //grid-template-columns: 1fr;
  margin: 0.5em;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`;

const Horeca = (props: HorecaProps) => {
  const variables: GetProductsVariables = {
    clientGroupCode: 'restaurants',
    where: {
      status: ProductStatus.ACTIVE,
      productAvailabilities_some: {
        endDate: null,
        clientGroup: {code: "restaurants"}
      }
    }
  };
  const {data} = useQuery<GetProducts>(GetProductsQuery, {
    variables
  });

  const [availableProducts, setAvailableProducts] = useState<GetProducts_products[]>([]);

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = () => {
    // const ws = XLSX.utils.json_to_sheet(flattenDeep(values(availableProducts)).map(product => {
    //   let price = findProductPriceForClientGroupCode(product, "detailhandel");
    //
    //   return ({
    //     "Product": product.name,
    //     "Eenheid": `${product.amount} ${translatedUnit(product.unit)}`,
    //     "Prijs excl btw": formatDoubleDigit(price?.value || 0),
    //     "Aantal": null,
    //   });
    // }));
    // const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
    // const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    // const data = new Blob([excelBuffer], {type: fileType});
    // saveAs(data, 'Bestellijst' + fileExtension);
  };

  useEffect(() => {
    if (data) {
      const clientGroup = data.clientGroup;
      if (clientGroup && data.products) {
        let products = data.products.filter(notEmpty).map(product => {
          const clientGroupProductDiscount = (product.discounts || []).find(d => d.clientGroup.id === clientGroup.id);
          let price = findProductPriceForClientGroupCode(product, "detailhandel");
          return {
            ...product,
            price: (price?.value || 0) * ((clientGroupProductDiscount ? clientGroupProductDiscount.discount : clientGroup.discount))
          };
        });
        setAvailableProducts(products);
      }
    }

  }, [data]);

  return <Container>
    <div>
      <h1>Ons huidig aanbod</h1>
      <div style={{color: '#EE7A31', fontWeight: 'bold'}}>Online bestellen is voorlopig nog niet mogelijk. Hier vinden
        jullie alvast wel het hudige aanbod. Bestellingen plaatsen kan via mail naar <a
          href="mailto:info@groentegeweld.be">info@groentegeweld.be</a>.
      </div>
      <Button label="Download bestellijst" onClick={(e) => exportToCSV()}/>
    </div>

    <Products>
      {availableProducts.map(product =>
        <Product
          inclVat={false}
          key={product.id} clientGroupId={props.clientGroup} product={product}
        />
      )}
    </Products>
  </Container>;
};

export default Horeca;
