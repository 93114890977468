import React from 'react';
import { compact, floor } from "lodash";
import { ProductPromoType, ProductUnit } from "../../__generated__/types";
import { formatDoubleDigit } from "../utils/currency.utils";
import styled from "styled-components";
import { productIsAvailableAsAlternativeUnitFor, units } from "../utils/product.utils";
import { amountWithUnit } from "../utils/unit.utils";

interface AmountCellProps {
  product: {
    amount: number | null,
    unit: ProductUnit | null,
    availableAsAlternativeUnitFor: string[];
    avgWeight: number | null;
    alternativeUnit: ProductUnit | null;
    productPromos?: {
      id: string,
      endDate?: string,
      type: ProductPromoType,
      value: number,
      secondValue: number | null,
    }[] | null,
  }
  item: {
    amount: number,
    currentPromo?: {
      id: string
    } | null,
  };
}

const Container = styled.div`
  > div {
    white-space: nowrap;
  }

  .promo {
    color: var(--invalid);
  }
`;

const AmountCell = (props: AmountCellProps) => {
  const {item, product} = props;
  const {amount, unit, productPromos} = product;
  const activePlusOnePromo = compact(productPromos)
    .find(promo => promo.id === item.currentPromo?.id);

  if (amount) {
    let shopAmount = amount * item.amount;
    let freeAmount = 0;
    if (activePlusOnePromo && activePlusOnePromo.secondValue) {
      freeAmount = floor(shopAmount / activePlusOnePromo.value) * activePlusOnePromo.secondValue;
    }

    {/*HARDCODED ID*/}
    {/*HARDCODED ID*/}
    {/*HARDCODED ID*/}
    let quantityAsString = product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, 'ckbuxfzg307wz0774ooelvsqa')
      ? amountWithUnit(shopAmount / product.avgWeight, product.alternativeUnit)
      : amountWithUnit(shopAmount, product.unit);

    {/*HARDCODED ID*/}
    {/*HARDCODED ID*/}
    {/*HARDCODED ID*/}
    let freeQuantityAsString = product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, 'ckbuxfzg307wz0774ooelvsqa')
      ? amountWithUnit(freeAmount / product.avgWeight, product.alternativeUnit)
      : amountWithUnit(freeAmount, product.unit);

    return <Container>
      <div>{quantityAsString}</div>
      {freeAmount > 0 && <div className="promo">
        + {freeQuantityAsString} gratis
      </div>}
    </Container>;
  }

  return <div/>;
};

export default AmountCell;
