import React, { useState } from 'react';
import { FormSection, FormWrapper, InlineFields } from "../../shared/components/soft-farms-forms";
import FormInput from "../../shared/components/Form/FormInput";
import FormCheckbox from "../../shared/components/Form/FormCheckbox";
import FormText from "../../shared/components/Form/FormText";
import { useMutation } from "@apollo/react-hooks";
import { CampaignEntryCreateMutation } from './CampaignEntry.gql';
import { CampaignEntryCreate, CampaignEntryCreateInput, CampaignEntryCreateVariables, MembershipType } from "../../__generated__/types";
import { omit, range } from "lodash";
import { date, object, string } from "yup";
import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import LocationDropdown from "./LocationDropdown";
import { deliveryLocations } from "../../shared/utils/deliveryLocation.utils";
import DateSelection from './DateSelection';
import { momentFromIso8601 } from "../../shared/utils/date.utils";
import { ExecutionResult } from "graphql";
import { Dialog } from "primereact/dialog";

interface CampaignPageProps {

}

type CampaignEntryType = {
  firstName: string;
  deliveryLocation: string | undefined;
  city: string;
  name: string;
  email: string;
  remarks: string;
  phone: string;
  newsletter: boolean;
  alternativeDate: boolean;
  deliveryDate: string | undefined;
  interestedInSelfHarvest: boolean;
  interestedInBaskets: boolean;
  interestUnknown: string;
  interestedInOnlineShop: boolean;
};

const DEFAULT_ENTRY: CampaignEntryType = {
  firstName: '',
  name: '',
  email: '',
  deliveryLocation: undefined,
  deliveryDate: undefined,
  city: '',
  remarks: '',
  phone: '',
  newsletter: true,
  alternativeDate: false,
  interestedInSelfHarvest: false,
  interestedInBaskets: false,
  interestUnknown: '',
  interestedInOnlineShop: false,
};
const CampaignPage = (props: CampaignPageProps) => {
  const [createCampaignEntry] = useMutation<CampaignEntryCreate>(CampaignEntryCreateMutation);
  const [newCampaignEntry, setNewCampaignEntry] = useState<Partial<CampaignEntryType>>(DEFAULT_ENTRY);
  const [submissionResult, setSubmissionResult] = useState<ExecutionResult<CampaignEntryCreate>>();

  let onHide = () => {
    window.location.href = `https://www.groentegeweld.be`;
  };
  return <div className="pb-4">
    {submissionResult && <Dialog
      header="Je gratis pakket komt eraan"
      visible onHide={onHide}>
      <p>We hebben je gegevens goed ontvangen. Je zal gecontacteerd worden om het afhalen van je pakket verder te
        regelen.</p>

      <Button
        label="Ga terug naar de website"
        className="p-button-outlined"
        onClick={onHide}
      ></Button>
    </Dialog>}
    <div
      style={{
        backgroundImage: "url('/lekkerstebuurt.png')",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: "no-repeat",
        width: '100%',
        height: '25vh',
      }}
      className="border-bottom-1 border-green flex justify-content-center align-items-center"
    >
      <img style={{maxHeight: '100%', maxWidth: '90%'}} src='/lekkerstebuurt-tekst.png'
           alt="Welkom in de lekkerste buurt"/>
    </div>

    <div className='mx-2 sm:mx-3 md:mx-4 lg:mx-auto my-4' style={{maxWidth: '1000px'}}>
      <h1 className="font-normal" style={{textTransform: 'none'}}>Bestel hier je gratis groentepakket!</h1>
      <p>Laat hieronder je gegevens achter en ontdek de smaak van lokale en verse groenten. <br/> Kies je afhaaldag
        en een afhaalpunt en wij contacteren je om je pakketje af te halen.
      </p>
      <Formik
        initialValues={newCampaignEntry}
        enableReinitialize
        onSubmit={async (x) => {
          const variables: CampaignEntryCreateVariables = {
            data: x as CampaignEntryCreateInput
          };
          const result = await createCampaignEntry({variables});

          setSubmissionResult(result);
          console.log(result);
        }}
        validationSchema={object().shape({
          'firstName': string().label('Voornaam').required('Voornaam is een verplicht veld.'),
          'name': string().label('Familienaam').required('Familienaam is een verplicht veld.'),
          'email': string().label('E-mailadres').required().email('Het opgegeven e-mailadres is niet geldig'),
          'deliveryLocation': string().label('Afhaalpunt').required('Afhaalpunt is een verplicht veld.'),
          'deliveryDate': date()
            .when('alternativeDate',
              {
                is: (alternativeDate: boolean) => !alternativeDate,
                then: date()
                  .transform(function (value, originalValue) {
                    if (this.isType(value)) {
                      return value;
                    }
                    const result = momentFromIso8601(originalValue).toDate();
                    return result;
                  })
                  .typeError("Geef een geldige datum op")
                  .label('Afhaaldag')
                  .required('Kies een afhaaldag')
              }
            ),
          'city': string().label('Gemeente').required(),
        })}
      >
        {(formik) => {
          const {isSubmitting, submitForm, resetForm} = formik;

          return <Form id="campaignForm">
            <FormWrapper>
              <h2>Persoonlijke gegevens</h2>
              <FormSection>
                <div className={'label'}>E-mailadres</div>
                <FormInput
                  name={'email'}
                  placeholder={'E-mailadres'}
                  value={formik.values.email}
                  onChange={(email) => {
                    if (!!email) {
                      setNewCampaignEntry(value => ({...value, email}));
                    } else {
                      setNewCampaignEntry(value => omit(value, 'email'));
                    }
                  }}/>

                <div className="label">Naam</div>
                <InlineFields grid={'1fr 2fr'}>
                  <FormInput
                    name={'firstName'}
                    placeholder={'Voornaam'}
                    value={formik.values.firstName}
                    onChange={(firstName) => {
                      if (!!firstName) {
                        setNewCampaignEntry(value => ({...value, firstName}));
                      } else {
                        setNewCampaignEntry(value => omit(value, 'firstName'));
                      }
                    }}
                  />
                  <FormInput
                    name={'name'}
                    placeholder={'Familienaam'}
                    value={formik.values.name}
                    onChange={(name) => {
                      if (!!name) {
                        setNewCampaignEntry(value => ({...value, name}));
                      } else {
                        setNewCampaignEntry(value => omit(value, 'name'));
                      }
                    }}
                  />
                </InlineFields>


                <div className={'label'}>Gemeente</div>
                <FormInput
                  name={'city'}
                  placeholder={'Gemeente'}
                  value={formik.values.city}
                  onChange={(city) =>
                    setNewCampaignEntry(value => ({...value, city: city || undefined}))
                  }/>

                <div className={'label'}>GSM-nummer</div>
                <FormInput
                  name={'phone'}
                  placeholder={'GSM-nummer'}
                  value={formik.values.phone}
                  onChange={(phone) => {
                    if (!!phone) {
                      setNewCampaignEntry(value => ({...value, phone}));
                    } else {
                      setNewCampaignEntry(value => omit(value, 'phone'));
                    }
                  }}
                />

                <h2>
                  Afhaalpunt & afhaaldag
                </h2>
                <div className="label">Afhaalpunt</div>
                <div>
                  <LocationDropdown
                    name="deliveryLocation"
                    placeholder="Kies jouw afhaalpunt"
                    value={formik.values.deliveryLocation}
                    onChange={(deliveryLocation) => {
                      if (!!deliveryLocation) {
                        setNewCampaignEntry(value => ({...value, deliveryLocation}));
                      } else {
                        setNewCampaignEntry(value => omit(value, 'deliveryLocation'));
                      }
                    }}
                    options={deliveryLocations.map(deliveryLocation => ({
                      label: deliveryLocation.label,
                      value: deliveryLocation.value,
                    }))}/>
                </div>

                <div className="label align-self-start">Afhaalmoment</div>
                <div>
                  <DateSelection
                    name="deliveryDate"
                    placeholder="Kies jouw afhaalmoment"
                    value={formik.values.deliveryDate}
                    onChange={(deliveryDate) => {
                      if (!!deliveryDate) {
                        setNewCampaignEntry(value => ({...value, deliveryDate}));
                      } else {
                        setNewCampaignEntry(value => omit(value, 'deliveryDate'));
                      }
                    }}/>
                  <div className="pt-2">
                    <FormCheckbox
                      name={'alternativeDate'}
                      value={!!formik.values.alternativeDate}
                      onChange={(alternativeDate) =>
                        setNewCampaignEntry(value => ({...value, alternativeDate}))
                      }
                      label="Contacteer mij voor een andere dag, bovenstaande data passen me niet."
                    />
                  </div>
                </div>
                <h2>Mijn interesse</h2>
                <div className={'label'}/>
                <div className="grid grid-nogutter gap-1">
                  <FormCheckbox
                    name={'interestedInSelfHarvest'}
                    value={!!formik.values.interestedInSelfHarvest}
                    onChange={(interestedInSelfHarvest) =>
                      setNewCampaignEntry(value => ({...value, interestedInSelfHarvest}))
                    }
                    label={'Ik heb interesse in zelfoogst.'}
                  />
                  <FormCheckbox
                    name={'interestedInBaskets'}
                    value={!!formik.values.interestedInBaskets}
                    onChange={(interestedInBaskets) =>
                      setNewCampaignEntry(value => ({...value, interestedInBaskets}))
                    }
                    label={'Ik heb interesse in pakketten.'}
                  />
                  <FormCheckbox
                    name={'interestedInOnlineShop'}
                    value={!!formik.values.interestedInOnlineShop}
                    onChange={(interestedInOnlineShop) =>
                      setNewCampaignEntry(value => ({...value, interestedInOnlineShop}))
                    }
                    label={'Ik heb interesse in de online hoevewinkel.'}
                  />
                  <FormCheckbox
                    value={!!formik.values.interestUnknown}
                    onChange={(interestUnknown) =>
                      setNewCampaignEntry(value => ({...value, interestUnknown: !interestUnknown ? '' : value.interestUnknown}))
                    }
                    label={'Ik heb interesse in ...'}
                    extra={() =>
                      <div className="flex flex-column">
                       <FormInput
                         name={'interestUnknown'}
                         placeholder={'vul aan'}
                         value={formik.values.interestUnknown}
                         onChange={(interestUnknown) =>
                           setNewCampaignEntry(value => ({...value, interestUnknown: interestUnknown || undefined}))
                         }/>
                      </div>}
                  />
                </div>
                <h2>Opmerkingen</h2>
                <div className={'label'}/>
                <FormText
                  name={'remarks'}
                  placeholder={'Heb je nog iets te melden? Geef het ons door en we contacteren je zo snel mogelijk.'}
                  value={formik.values.remarks}
                  onChange={(remarks) => {
                    setNewCampaignEntry(value => ({...value, remarks}))
                  }}
                />
              </FormSection>
              <FormCheckbox
                name={'newsletter'}
                value={!!formik.values.newsletter}
                onChange={(newsletter) =>
                  setNewCampaignEntry(value => ({...value, newsletter}))
                }
                label={'Ik schrijf me in voor de algemene nieuwsbrief via e-mail.'}
              />

              <div className="flex justify-content-end my-2">
                {!isSubmitting && <Button
                  label="Bestel mijn gratis pakket"
                  icon="pi pi-check"
                  onClick={submitForm}
                />}
              </div>
            </FormWrapper>
          </Form>;
        }}
      </Formik>

    </div>
  </div>;
};

export default CampaignPage;
