import React from 'react';
import { Content, FullImage } from "../App";
import { Grid } from "../pages/home";
import styled from "styled-components";

interface FooterProps {

}

const Main = styled.div`
  padding: 1vh 1vw;
`;


const Biolabel = styled.img`
  width: 100px !important;
`;

const Socials = styled.div`
  margin-top: 32px;

  i {
    font-size: 3em;
    padding-right: 16px;
  }
`;


const Footer = (props: FooterProps) => {
  return <div style={{backgroundColor: 'white'}}>
    <Content>
      <Main>
        <Grid columns={'1fr 2fr'}>
          <div>
            <h2>Contact</h2>
            <span>Groentegeweld</span><br/>
            <span>Weeldestraat 168</span><br/>
            <span>2381 Weelde</span><br/>
            <br/>
            <span>Willem: +32 494 66 43 46</span><br/>
            <span>Dirk: +32 489 95 51 13</span><br/>
            <br/>

            <span><a href={'mailto:info@groentegeweld.be'}>info@groentegeweld.be</a></span>
            <br/>
            <br/>
            <span>BTW BE0746.361.154</span>
            <br/>
            <span><a href={'/voorwaarden'}>Algemene voorwaarden</a></span>
            <br/>
            <Socials>
              <a href={'https://www.facebook.com/groentegeweld'} target={'_blank'}>
                <i className={'pi pi-facebook'}/>
              </a>
              <a href={'https://www.instagram.com/groentegeweld/'} target={'_blank'}>
                <i className={'pi pi-instagram'}/>
              </a>
            </Socials>
            <a
              href={'https://integra.tuv-nord.com/other/pdfcertificates/Cert/Certout/50291_GROENTEGEW_BPD_wet_Vl_20210628_CER_Certificate_Unified_v.2_NL_7983.pdf'}
              target={'_blank'}>
              <Biolabel src={'biolabel.png'}/>
            </a>
          </div>
          <div>
            <FullImage img={'contact.jpg'}/>
          </div>
        </Grid>
      </Main>
    </Content>
  </div>;
};

export default Footer;
