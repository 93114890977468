import React from 'react';
import { Steps } from "primereact/steps";
import styled from "styled-components";

export interface WizardStep {
  label: string;
}

interface SFStepsProps {
 steps: WizardStep[];
 activeStepIdx: number;
 goToStep: (idx: number) => void;
}

const StepsHolder = styled.div`
  .p-steps-item {
    .p-menuitem-link {
      background: transparent;
      .p-steps-title {
        display: none;
      }
    }


    &.p-highlight {
     .p-menuitem-link .p-steps-title {
        display: inline;
      }
    }
  }
`;

const SFSteps = (props: SFStepsProps) => {
  return <StepsHolder style={{marginBottom: '32px'}}>
    <Steps model={props.steps} activeIndex={props.activeStepIdx}
           onSelect={(e) => {
             if (e.index < props.activeStepIdx) {
               props.goToStep(e.index);
             }
           }}
           readOnly={false}/>
  </StepsHolder>;
};

export default SFSteps;
