import { boolean, object, string } from "yup";

export const validationSchema = [
  object().shape({}),
  object().shape({
    'firstName': string().label('Voornaam').required(),
    'name': string().label('Familienaam').required(),
    'email': string().label('E-mailadres').required().email('Het opgegeven e-mailadres is niet geldig'),
    'deliveryLocation': string().label('Afhaalpunt').required(),
    'deliveryDate': string().label('Afhaalmoment').required(),
    'packaging': string().label('Verpakking').required(),
  }),
  object().shape({


    'paymentConfirmed': boolean().label('Betaling').required(),
  }),
];
