import React, { useEffect } from 'react';
import { useField } from "formik";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";
import moment from 'moment';
import { asDayFullDdMmYyyy, asIso8601 } from "../../shared/utils/date.utils";
import { flatten, times } from 'lodash';

interface DateSelectionProps {
  name: string;
  value: string | undefined;
  placeholder: string;
  onChange: (value: string | undefined) => void;
}

const DateSelection = (props: DateSelectionProps) => {
  const [field, meta, helper] = useField(props.name);

  const isFormFieldValid = () => !!(meta.touched && meta.error);
  const getFormErrorMessage = () => {
    return isFormFieldValid() && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    helper.setValue(props.value);
  }, [props.value]);

  let today = moment();
  let firstDeliveryDate = today.isoWeekday() === 1 // maandag
    ? today.isoWeekday(3) // eerste woensdag
    : today.isoWeekday() < 5 // voor vrijdag
      ? today.isoWeekday(6) // eerste zaterdag
      : today.isoWeekday(3); // eerste woensdag

  let possibleDeliveryDates = flatten(times(4).map(offset => {
    let week = firstDeliveryDate.clone().add(offset, 'week');
    let daysInWeek = [];
    let wednesdayInWeek = week.clone().startOf('isoWeek').isoWeekday(3);
    let saturdayInWeek = week.clone().startOf('isoWeek').isoWeekday(6);
    if (wednesdayInWeek.isSameOrAfter(firstDeliveryDate, 'day')) {
      daysInWeek.push(wednesdayInWeek);
    }
    if (saturdayInWeek.isSameOrAfter(firstDeliveryDate, 'day')) {
      daysInWeek.push(saturdayInWeek);
    }

    return daysInWeek;
  }));

  return <div className="w-full flex flex-column">
    <Dropdown
      {...field}
      // style={{maxWidth: '310px'}}
      placeholder={props.placeholder}
      value={props.value}
      options={possibleDeliveryDates.map(date => ({
        label: `${date.format('dddd DD MMMM')} ${date.isoWeekday() === 3 ? '14u - 16u' : '10u - 12u'}`,
        value: asIso8601(date),
      }))}
      showClear
      onChange={e => {
        helper.setValue(e.value);
        props.onChange(e.value);
      }}
    />
    <div>{getFormErrorMessage()}</div>
  </div>;
};

export default DateSelection;
