import gql from "graphql-tag";

export const ProductByCodeQuery = gql`
query ProductByCode($code: String!) {
  product(where: {code: $code}) {
    id
    name
    vat
    code
    productPrices(where: {
      clientGroup: {
        code: "standaard"
      }
      endDate: null
    }) {
      value
      startDate
      endDate

    }
  }
}
`;

export const ProductsByIdQuery = gql`
query ProductsById($ids: [ID!]) {
  products(where: {id_in: $ids}) {
    id
    name
    vat
    code
    productPrices(where: {
      clientGroup: {
        code: "standaard"
      }
      endDate: null
    }) {
      value
      startDate
      endDate

    }
  }
}
`;

export const ProductByIdQuery = gql`
query ProductById($id: ID!) {
  product(where: {id: $id}) {
    id
    name
    vat
    code
    productPrices(where: {
      clientGroup: {
        code: "standaard"
      }
      endDate: null
    }) {
      value
      startDate
      endDate

    }
  }
}
`;
