import React from 'react';
import ShoppingCart from "./ShoppingCart";
import { Button } from "primereact/button";
import { values } from "lodash";
import { useShop } from "../../Shop.context";
import styled from "styled-components";
import { useFormikContext } from "formik";

interface ShoppingCartContainerProps {

}

const Container = styled.div`
  h1 {
    font-weight: 800;
    text-transform: uppercase;
    color: #748F56;
    margin-top: 2vh;

    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.5em;

    font-size: 30px;
    i {
      font-size: 30px;
    }

  }
`;

const ShoppingCartContainer = (props: ShoppingCartContainerProps) => {
  const {shopDetails: {cart}} = useShop();

  const {submitForm} = useFormikContext();

  return <Container>
    <h1><i className="icon-wheelbarrow"/> Mijn oogstmand</h1>
    <ShoppingCart/>
    <form id={'shopWizard'}>
      <Button
        style={{width: '100%'}}
        disabled={values(cart.items).length === 0}
        label={'Plaats mijn bestelling'}
        onClick={submitForm}
      />
    </form>
  </Container>;
};

export default ShoppingCartContainer;
