import React from 'react';
import classNames from "classnames";
import { orderBy } from "lodash";
import { DeliveryLocationDetail, deliveryLocations } from "../../../../../../src/shared/utils/deliveryLocation.utils";
import styled from "styled-components";
import { DeliveryLocation } from "../../../../../__generated__/types";


const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-auto-rows: max-content;
  grid-gap: 8px;
  @media only screen and (min-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 4px;
  }
  margin-bottom: 32px;

  padding: 4px;

  &.p-invalid {
    border: 1px solid var(--invalid);
  }
`;

const DeliveryLocationOption = styled.div`
  //width: 175px;
  border: 1px solid var(--green);
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 16px;
  padding: 8px;
  @media only screen and (max-width: 500px) {
    padding: 4px;
    grid-gap: 4px;
    font-size: 90%;
  }
  //flex-direction: row;
  align-items: center;
  //box-sizing: border-box;

  &:not(.static) {
    cursor: pointer;

    &:hover, &.selected {
      opacity: 1;
      background-color: var(--green-40);
      border-width: 2px;
    }
  }

  img {
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
    height: 50px;
  }

  label {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 4px;
    align-items: center;
  }

  .city {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

interface DeliveryLocationsProps {
  onClick?: (deliveryLocationOption: DeliveryLocationDetail) => void;
  value?: DeliveryLocation;
  field?: any;
  static: boolean;
  hideAddress?: boolean;
  filters?: (deliveryLocation: DeliveryLocationDetail) => void;
}

const DeliveryLocations = (props: DeliveryLocationsProps) => {
  const meta = props.field && props.field[1];

  const isFormFieldValid = () => props.field && !!((meta.touched) && meta.error);
  let collection = deliveryLocations;
  if (props.filters) {
    collection = collection.filter(props.filters);
  }
  return <Container className={classNames({'p-invalid': isFormFieldValid()})}>
    {orderBy(collection, [(deliveryLocation => deliveryLocation.value === DeliveryLocation.WEELDE),'label'], ['desc', 'asc']).map(deliveryLocationOption => <DeliveryLocationOption
      onClick={() => props.onClick && props.onClick(deliveryLocationOption)}
      className={classNames({
        selected: props.value === deliveryLocationOption.value,
        static: props.static
      })}
    >
      <img src={'/deliveryLocations/' + deliveryLocationOption.logo} alt={deliveryLocationOption.name}/>
      <div>
        <div className={'city'}>{deliveryLocationOption.city}</div>
        <div className={'address'}>{deliveryLocationOption.name}</div>
        {!props.hideAddress && <div className={'address'}>{deliveryLocationOption.address}</div>}
      </div>
      {/*</label>*/}

    </DeliveryLocationOption>)}
  </Container>;
};

export default DeliveryLocations;
