import React from 'react';
import { Button } from "primereact/button";
import { values } from "lodash";
import { Column } from "primereact/column";
import styled from 'styled-components';
import { useShop } from "../../Shop.context";
import { ShopProduct } from "../../Shop";
import PriceCell from "./PriceCell";
import PriceFooterCell from "./PriceFooterCell";
import AmountCell from "../../../../shared/components/AmountCell";
import { ambassadorDiscountPercentage } from "../../../../shared/utils/ambassador.utils";
import { DataTable } from "primereact/datatable";
import { PROMOCODE } from "../../../../config.app";
import { formatCurrencySign } from "../../../../../src/shared/utils/currency.utils";

interface ShoppingCartProps {
}

export interface ShoppingCartItem {
  amount: number;
  product: ShopProduct;
  priceIncl: number;
  currentPromo?: { id: string };
}

const Container = styled.div`
`;

const ShoppingCart = (props: ShoppingCartProps) => {
  const {shopDetails: {clientEmail, cart, promocode, packaging, ambassador}, removeFromShoppingCart} = useShop();

  return <Container>
    <DataTable<ShoppingCartItem[]>
      className={"sf-table"}
      value={values(cart.items)}
      emptyMessage={'Er liggen nog geen groenten in je oogstmand.'}
    >
      <Column header={'Omschrijving'} field={'product.name'}/>
      <Column
        header={'Aantal'}
        field={'amount'}
        footer={() => <div style={{textAlign: 'right'}}>
          <strong>Subtotaal:</strong><br/>
          {packaging && <><strong>Verpakking:</strong><br/></>}
          {ambassador && ambassadorDiscountPercentage(ambassador) > 0 && <>
            <strong>Ambassadeurskorting:</strong><br/>
          </>}
          {promocode === PROMOCODE && <>
            <strong>Korting:</strong><br/>
          </>}
          {(packaging || (promocode === PROMOCODE)) && <>
            <strong>Totaal:</strong><br/>
          </>}
        </div>}
        body={(item: ShoppingCartItem) => <AmountCell item={item} product={item.product}/>}/>
      <Column
        header={'Prijs'}
        field={'price'}
        footer={(columnFooterOptions) =>
          <PriceFooterCell items={columnFooterOptions.props.value as ShoppingCartItem[]}/>}
        body={(item: ShoppingCartItem) => <PriceCell item={item}/>}
      />
      <Column
        body={(item: ShoppingCartItem) =>
          <Button
            style={{width: '20px', padding: 0}}
            type={'button'}
            icon={'pi pi-trash'}
            className={'p-button-sm p-button-text p-button-danger'}
            onClick={() =>
              removeFromShoppingCart(item.product.id)}
          />
        }/>

    </DataTable>
  </Container>;
};

export default ShoppingCart;
