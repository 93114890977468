import React from 'react';
import styled from "styled-components";
import { useShop } from "../Shop.context";
import { deliveryLocations } from "../../../../src/shared/utils/deliveryLocation.utils";
import { asDayFullDdMmYyyy, momentFromIso8601 } from "../../../../src/shared/utils/date.utils";
import ShoppingCart from "./ShoppingCart/ShoppingCart";
import { formatCurrency, formatCurrencySign } from "../../../../src/shared/utils/currency.utils";
import { values } from "lodash";
import FormCheckbox from "../../../shared/components/Form/FormCheckbox";
import { paymentDescription, totalPrice } from "../../../shared/utils/Shop.utils";
import { PACKAGING_OPTIONS } from "./PersonalStep/Packaging";
import { ambassadorDiscountPercentage } from "../../../shared/utils/ambassador.utils";
import { PROMOCODE } from "../../../config.app";

interface OverviewStepProps {
}

const Block = styled.div`
  border: 1px solid var(--orange);
  padding: 16px;

  &.highlight {
    border: 1px solid var(--green);
    background-color: var(--green-20);
  }
`;

const Listing = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 4px 16px;

  .label {
    text-align: right;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  .shoppingCart {
    grid-row: 1 / span 2;
  }

  .payment {
    grid-column: 1 / span 2;
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    .shoppingCart {
      grid-row: 1 / span 1;
    }

    .payment {
      grid-column: 1 / span 1;
    }
  }
`;

const OverviewStep = (props: OverviewStepProps) => {
  const {shopDetails, setPersonalField} = useShop();
  const {
    phone,
    newsletter,
    firstName,
    name,
    email,
    deliveryLocation,
    deliveryDate,
    promocode,
    paymentConfirmed
  } = shopDetails;

  let deliveryLocationData = deliveryLocations.find(d => d.value === deliveryLocation);
  let packagingValue = PACKAGING_OPTIONS.find(packaging => packaging.value === shopDetails.packaging)?.price;
  let ambassadorDiscount = shopDetails.ambassador ? ambassadorDiscountPercentage(shopDetails.ambassador) : 0;
  return <div>
    <h1>Bestelling afronden</h1>
    <Wrapper>
      <Block>
        <h2>Persoonlijke gegevens</h2>
        <Listing>
          <div className={'label'}>Naam</div>
          <div>{firstName} {name}</div>
          <div className={'label'}>Telefoon</div>
          <div>{phone} </div>
          <div className={'label'}>E-mailadres</div>
          <div>{email}</div>
          <div className={'label'}>Nieuwsbrief</div>
          <div>{newsletter ? 'Ja' : 'Nee'}</div>
        </Listing>
      </Block>

      <Block>
        <h2>Afhaalgegevens</h2>
        <Listing>
          {deliveryLocationData &&
            <>
              <div className="label">Afhaalpunt</div>
              <div>
                <div>{deliveryLocationData.name}</div>
                <div>{deliveryLocationData.address}</div>
                <div>{deliveryLocationData.city}</div>
              </div>
            </>
          }
          {deliveryDate && deliveryLocationData &&
            <>
              <div className={'label'}>Tijdstip</div>
              <div>
                <div>{asDayFullDdMmYyyy(momentFromIso8601(deliveryDate))}</div>
                <div>{deliveryLocationData.shopHours}</div>
              </div>
            </>
          }

          <div className={'label'}>Verpakking</div>
          <div>
            <div>{packagingValue && packagingValue > 0 ? formatCurrencySign(packagingValue) : 'Gratis'}</div>
          </div>

        </Listing>
      </Block>

      <Block className={'shoppingCart'}>
        <h2>Jouw bestelling</h2>
        <ShoppingCart/>
      </Block>

      <Block className={'highlight payment'}>
        <h2>Betaling</h2>
        <p>Betaling kan voorlopig enkel via overschrijving.</p>
        <p><strong>Let op:</strong> Jouw bestelling wordt enkel gemaakt als we jouw betaling hebben ontvangen.</p>

        <Listing>
          <div className={'label'}>Rekeningnummer</div>
          <div>BE46 5230 8118 5136</div>
          <div className={'label'}>Mededeling</div>
          <div>{paymentDescription(shopDetails.deliveryDate, shopDetails.name)}</div>
          <div className={'label'}>Bedrag</div>
          <div>{formatCurrency(totalPrice(values(shopDetails.cart.items), shopDetails.packaging, ambassadorDiscount, shopDetails.promocode))}</div>
          <div className="label">Bevestiging</div>
          <div>
            <FormCheckbox
              name={'paymentConfirmed'}
              value={!!paymentConfirmed}
              onChange={(value) => setPersonalField('paymentConfirmed', value)}
              label={'Ik bevestig mijn betaling te hebben uitgevoerd.'}
            />
          </div>
        </Listing>
      </Block>
    </Wrapper>
  </div>;
};

export default OverviewStep;
