import gql from "graphql-tag";

export const ProductFieldsFragment = gql`
fragment ProductFields on Product {
  id
  name
  code
  image
  unit
  amount
  availableAsAlternativeUnitFor
  alternativeUnit
  avgWeight
  latestStock: stock(
    orderBy: createdAt_DESC,
    first: 1
  ) {
    value
  }
  productAvailabilities(where: {endDate: null }) {
    id
    clientGroup { id code }
    startDate
    endDate
    note
  }
}
`;

export const GetProductsQuery = gql`
${ProductFieldsFragment}
query GetProducts($where: ProductWhereInput!, $clientGroupCode: String) {
  clientGroup(where: {
    code: $clientGroupCode
  }) {
    code
    default
    discount
    id
    name
  }
	products(
    where: $where,
    orderBy: name_ASC,
  ) {
    ...ProductFields
    alternativeUnit
    availableAsAlternativeUnitFor
    avgWeight
    minOrderQuantity
    portionDirk
    portionWillem
    vat
    productPrices(where: {endDate: null}) {
      id
      clientGroup { id code }
      value
      base
      endDate
      startDate
      note
      unit
    }
    discounts {
      id
      clientGroup { id }
      discount
      product { id }
    }
    category {
      id
      name
      image
    }
    productPromos {
      id
      startDate
      endDate
      type
      value
      secondValue
    }
  }
}
`;

export const GetProducts_simpleQuery = gql`
query GetProducts_Simple($where: ProductWhereInput!) {
  products(
    where: $where
  ) {
    id
    name
    code
    image
    unit
    amount
    availableAsAlternativeUnitFor
    alternativeUnit
    avgWeight
    minOrderQuantity
    portionDirk
    portionWillem
    vat
    category {
      id
      name
      image
    }
  }
}
`;

export const ProductsQuery = gql`
${ProductFieldsFragment}
query Products($promoEndDate: DateTime!) {
	products(
    where: {
      status: ACTIVE
    }
  ) {
    ...ProductFields
    portionDirk
    portionWillem
    productPrices(where: {OR: [{endDate_gte: $promoEndDate}, {endDate: null}]}) {
      id
      base
      note
      unit
      value

      endDate
      startDate
      clientGroup {
        id
      }
    }
    status
    category {
      id
    }
    productPromos(where: {
      OR: [{endDate_gte: $promoEndDate}, {endDate: null}]
    }) {
     id
     startDate
     endDate
    }
    productAvailabilities(where: {endDate: null }) {
      id
      clientGroup { id code }
      startDate
      endDate
      note
    }
  }
}
`;

export const ProductQuery = gql`
${ProductFieldsFragment}
query Product($id: ID!) {
	product(
    where: {
      id: $id
    }
  ) {
    ...ProductFields
    groupName
    extra
    portionWillem
    portionDirk
    avgWeight
    productAvailabilities(where: {endDate: null }) {
      id
      clientGroup { id code }
      startDate
      endDate
      note
    }
    status
    vat
    type
    category {
      id
      name
      code
    }
    frequencies {
      id
      code
    }
    duration
  }
}
`;

export const ProductPricesQuery = gql`
query ProductPrices($productId: ID!) {
	productPrices(
    where: {
      product: {id: $productId}
    }
  ) {
    id
    value
    base
    startDate
    note
    unit
    endDate
    clientGroup {
      id
      code
    }
  }
}
`;

export const ProductFrequenciesQuery = gql`
query ProductFrequencies {
  productFrequencies {
    id
    code
    name
  }
}`;

export const CreateProductPriceMutation = gql`
mutation CreateProductPrice($data: ProductPriceCreateInput!) {
	createProductPrice(
    data: $data
  ) {
    id
  }
}
`;

export const UpdateProductPriceMutation = gql`
mutation UpdateProductPrice($id: ID!, $data: ProductPriceUpdateInput!) {
	updateProductPrice(
	  where: {id: $id}
    data: $data
  ) {
    id
  }
}
`;

export const ProductCategoriesQuery = gql`
query ProductCategories {
	productCategories {
    id
    name
    code
    image
    visibleToCustomer
    category {
      id
    }
  }
}
`;

export const ProductCategoryUpsertMutation = gql`
mutation ProductCategoryUpsert(
  $id: ID
  $create: ProductCategoryCreateInput!
  $update: ProductCategoryUpdateInput!
) {
	upsertProductCategory(
	  where: {id: $id}
	  create: $create
	  update: $update
	) {
    id
    name
    code
    image
    visibleToCustomer
    category {
      id
    }
  }
}`;


export const ProductCategoryUpdateMutation = gql`
mutation ProductCategoryUpdate(
  $id: ID
  $update: ProductCategoryUpdateInput!
) {
	updateProductCategory(
	  where: {id: $id}
	  data: $update
	) {
    id
    name
    code
    image
    visibleToCustomer
    category {
      id
    }
  }
}`;

export const GetProductsForSubscriptionQuery = gql`
query GetProductsForSubscription {
  products(where: {
    type: SUBSCRIPTION
  }) {
    id
    name
    code
    status
    type
    vat
    frequencies {
      id
      code
      name
    }
    productPrices(where: {
      clientGroup: {
        code: "standaard"
      }
      endDate: null
    }) {
      value
      startDate
      endDate

    }
  }
}
`;

export const ProductStockLatestValueQuery = gql`
  query ProductStockLatestValue($id: ID!) {
  productStocks(
    where: {
      product: {id: $id}
    },
    orderBy: createdAt_DESC
    first: 1
  ) {
    value
  }
}
`;
