import { Grid } from 'pages/home';
import React from 'react';
import { Content, FullImage } from "../../App";

const CsaPage = () => {
  return <div>
    <FullImage img={'csa.jpg'}/>
    <Content>
      <h1>Wat is CSA?</h1>
      <Grid columns={'3fr 2fr'}>
        <div>
          <p>Je las al dat je bij ons een oogstaandeelhouder wordt. Waarom koop ik niet gewoon 1 kg wortelen, maar een
            ‘aandeel’? Dit komt vanuit het CSA-principe, oftewel: Community Supported (of Shared) Agriculture.</p>
          <p>We geloven er sterk in dat landbouw zich opnieuw moet verbinden met zijn ‘eters’. Door rechtstreeks bij de
            boer
            te kopen, doe je dat! Maar de boer luistert ook naar zijn klanten (bv. op jaarvergaderingen of gewoon op het
            veld). Van beide kanten komen ideeën en we delen de opbrengst van het veld. Als aandeelhouder deel je dus in
            de
            risico’s (bv. als een teelt mislukt), maar ook in de overvloed als bepaalde groenten echt heel veel
            opbrengst
            geven.</p>
          <p>In de mate van het mogelijke werken we met vooruitbetalingen, op deze manier zijn we zeker dat de
            werkingskosten
            gedekt zijn én streven we naar een duurzaam loon voor de boer! Dat laatste is in de landbouw niet evident.
            Wil
            je
            verder gaan in je engagement dan kun je Groentegeweldige ambassadeur worden. Voor een kleine bijdrage ben
            jij
            voor
            één jaar mee het gezicht van Groentegeweld!</p>

          <a href={'http://www.csa-netwerk.be/csa.asp'} target={'_blank'}>Lees er alles over op de website van het CSA
            netwerk.</a>
        </div>
        <div>
          <img src={'csa1.jpg'} alt={'CSA'}/>
        </div>
      </Grid>
      <FullImage img={'csa2.jpg'} style={{marginTop: '5vh'}}/>
    </Content>

  </div>;
};

export default CsaPage;
