import React, {useEffect, useState} from 'react';
import {DataView, DataViewLayoutOptions} from "primereact/dataview";
import {GetProducts_clientGroup, GetProducts_products} from "../../__generated__/types";
import ProductItemTemplateGrid from "../components/ProductItem/ProductItemTemplateGrid";
import ProductItemTemplateList from '../components/ProductItem/ProductItemTemplateList';

interface ProductListProps {
  onAdd: (productId: string, quantity: number, portions: number) => void;
  products: GetProducts_products[];
  clientGroup: GetProducts_clientGroup;
  showIn: 'currency' | 'points';
  disable?: boolean;
}

const ProductList = (props: ProductListProps) => {
  const [products, setProducts] = useState<GetProducts_products[]>([]);
  const [layout, setLayout] = useState<'list' | 'grid' | (string & Record<string, unknown>)>('grid');

  useEffect(() => {
    setProducts(props.products);
  }, [props.products]);

  const itemTemplate = (product: GetProducts_products, layout: 'list' | 'grid' | (string & Record<string, unknown>)) => {
    if (!product) {
      return;
    }

    if (layout === 'list') return <ProductItemTemplateList
      showIn={props.showIn}
      product={product}
      disable={props.disable}
      clientGroup={props.clientGroup}
      onAdd={(productId: string, quantity: number, portions: number) => {
        props.onAdd(productId, quantity, portions);
      }}
    />;
    else if (layout === 'grid') return (<ProductItemTemplateGrid
      showIn={props.showIn}
      product={product}
      disable={props.disable}
      clientGroup={props.clientGroup}
      onAdd={(productId: string, quantity: number, portions: number) => {
        props.onAdd(productId, quantity, portions);
      }}
    />);
  };

  return (
    <div className="">
      {/*<div className="flex justify-content-between align-items-start surface-0 flex-column">*/}
      {/*  <div className="text-600">Filters:</div>*/}
      {/*  <CategoryFilter/>*/}
      {/*</div>*/}
      <div className="border-top-1 border-300 flex justify-content-end pt-2 pb-2">
        <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)}/>
      </div>
      <DataView
        //value={products.filter(product => product.id === "ckoddd8l3051i0775wjaxjdbf" || product.id === "cl6y1thnk7pvh0775rhkqwisa")}
        value={products}
        className="surface-0"
        itemTemplate={itemTemplate}
        layout={layout}
        dataKey={'id'}
        rows={60}
        paginator
        sortOrder={1}
        paginatorPosition="bottom"
        sortField={"name"}
      />
    </div>
  )
};

export default ProductList;
