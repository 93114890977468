import React, { useEffect, useState } from 'react';
import { Tag } from "primereact/tag";
import placeholder from "../../placeholder.svg";
import { amountWithUnit, formatAsPoints } from "../../utils/unit.utils";
import { Button } from "primereact/button";
import { GetProducts_clientGroup, GetProducts_products } from "../../../__generated__/types";
import { findProductPriceForClientGroup, findProductPriceForClientGroupCode, portionsBasedOnProductOrderAmount, priceInclBasedOnProductOrderAmount, productIsAvailableAsAlternativeUnitFor } from "../../utils/product.utils";
import ProductQuantityInput from "../ProductQuantityInput";
import { first } from "lodash";
import classNames from "classnames";
import { formatCurrency } from "../../utils/currency.utils";

interface ProductItemTemplateListProps {
  showIn: "currency" | "points";
  product: GetProducts_products;
  disable?: boolean;
  clientGroup: GetProducts_clientGroup;
  onAdd: (productId: string, quantity: number, portions: number) => void;
}

const getSeverity = (product: GetProducts_products) => {
  let stock = first(product.latestStock);
  if (stock) {
    if (stock.value < 1) {
      return 'danger';
    } else if (stock.value < 10) {
      return 'warning';
    }
    return 'success';
  }
  return 'warning';
};

const getSeverityValue = (product: GetProducts_products, clientGroup: GetProducts_clientGroup) => {
  let stock = first(product.latestStock);
  if (stock) {
    if (stock.value < 1) {
      return 'Uitverkocht';
    } else if (stock.value < 10) {
      const productPrice = findProductPriceForClientGroupCode(product, clientGroup.code)
      const unit = productIsAvailableAsAlternativeUnitFor(product, productPrice?.clientGroup?.id) ? product.alternativeUnit : product.unit;
      return `${amountWithUnit(stock.value, unit)} beschikbaar`;
    }
    return 'Beschikbaar';
  }
  return 'Voorraad onbekend';
};

let stock = (product: GetProducts_products, clientGroup: GetProducts_clientGroup) =>
  <Tag value={getSeverityValue(product, clientGroup)} severity={getSeverity(product)} className="opacity-70"/>;

let productImg = (product: { name: string, image?: string | null | undefined }) =>
  <img className="w-5rem lg:w-7rem xl:w-8rem border-round"
       src={product.image || placeholder}
       alt={product.name}/>;

let productDescription = (product: GetProducts_products, clientGroup: GetProducts_clientGroup) =>
  <div className="flex align-items-center flex-grow-1">
    {productImg(product)}
    <div className="pl-2">
      <div className="text-l md:text-xl font-bold">{product.name}</div>
      {/*<div className="text-xs md:text-sm lg:text-base text-500">{product.category?.name}</div>*/}
    </div>
    <div className="flex-grow-1 pl-2 text-right">
      {stock(product, clientGroup)}
    </div>
  </div>;

let productUnit = (product: GetProducts_products,
                   clientGroupId: string | undefined | null,
                   selectedQuantity: number,
                   showIn: "currency" | "points",
                   onChange: (quantity: number | null) => void) => {
  let productPrice = findProductPriceForClientGroup(product, clientGroupId);
  let selectedPortions = portionsBasedOnProductOrderAmount(product, productPrice, selectedQuantity, clientGroupId);
  let selectedPrice = priceInclBasedOnProductOrderAmount(product, productPrice, selectedQuantity, clientGroupId);
  let currentStock = first(product.latestStock);

  return <>{productPrice && <div className="flex align-items-center">
    <ProductQuantityInput
      onChange={onChange}
      clientGroupId={clientGroupId}
      product={product}
      inputClassName={'w-3rem'}
      value={selectedQuantity}
      hideSaveButton
      disabled={currentStock && currentStock.value < 1}
    />
    <div className="text-800 text-sm md:text-base xl:text-lg font-semibold pl-1">
      = {showIn === "points"
      ? formatAsPoints(selectedPortions)
      : formatCurrency(selectedPrice)
    }
    </div>
  </div>
  }</>;
};

const ProductItemTemplateList = (props: ProductItemTemplateListProps) => {
  const {product, clientGroup} = props;
  const  clientGroupId = clientGroup.id;

  const [selectedQuantity, setSelectedQuantity] = useState<number>(0);

  useEffect(() => {
    let availableAsAlternativeUnit = productIsAvailableAsAlternativeUnitFor(product, clientGroupId);
    if (product.amount) {
      if (product.avgWeight && availableAsAlternativeUnit) {
        setSelectedQuantity(product.avgWeight / product.amount);
      } else {
        setSelectedQuantity(product.amount);
      }
    }
  }, [props.product, clientGroupId]);

  let currentStock = first(product.latestStock);

  return <div className="col-12 p-2 flex border-0">
    <div
      className="p-2 border-1 surface-border surface-card border-round w-full flex flex-column xl:flex-row justify-content-between">
      <div className="flex flex-wrap align-items-center justify-content-between gap-1 md:gap-2 xl:flex-column">
        {productDescription(product, props.clientGroup)}
      </div>
      <div className={
        classNames(
          "flex align-items-center justify-content-between",
          {"opacity-40": currentStock && currentStock.value < 1}
        )
      }>
        {productUnit(product, clientGroupId, selectedQuantity, props.showIn, (quantity: number | null) => {
          setSelectedQuantity(quantity || 0);
        })}
        {<Button
          type="button"
          icon="pi pi-shopping-cart"
          className="p-button-rounded w-2rem h-2rem"
          disabled={(currentStock && currentStock.value < 1) || props.disable}
          onClick={() => {
            let portions = portionsBasedOnProductOrderAmount(product, findProductPriceForClientGroup(product, clientGroupId), selectedQuantity);
            props.onAdd(product.id, selectedQuantity, portions);
          }}
        />}
      </div>
    </div>
  </div>;
};

export default ProductItemTemplateList;
