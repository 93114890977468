import React, { useEffect, useState } from 'react';
import { Content } from "../../App";
import { useQuery } from "@apollo/react-hooks";
import { GetUserInfo } from "../../__generated__/types";
import { GetUserInfoQuery } from "../../shared/queries.gql";
import AccountCompletion from "./components/AccountCompletion";

interface AccountPageProps {

}

const AccountPage = (props: AccountPageProps) => {
  const [userNonExistent, setUserNonExistent] = useState<boolean>(true);

  const {data, loading} = useQuery<GetUserInfo>(GetUserInfoQuery);


  useEffect(() => {
    if (data && data.user) {
      setUserNonExistent(false);
    }
  }, [data]);

  return <Content>
    <h1>Mijn account</h1>

    {!loading && userNonExistent && <AccountCompletion/>}

  </Content>;
};

export default AccountPage;
