import React, { useEffect } from 'react';
import { useShop } from "../Shop.context";
import { FormSection, FormWrapper, InlineFields } from "../../../shared/components/soft-farms-forms";
import FormCheckbox from "../../../shared/components/Form/FormCheckbox";
import FormInput from "../../../shared/components/Form/FormInput";
import Delivery from "./PersonalStep/Delivery";
import styled from "styled-components";
import Packaging from "./PersonalStep/Packaging";
import { useLazyQuery } from "@apollo/react-hooks";
import { FindShopClient, FindShopClient_shopClients } from "../../../__generated__/types";
import { FindShopClientQuery } from "../Shop.gql";
import { compact, first, flatten, get, map } from "lodash";
import { Message } from "primereact/message";
import { PROMOCODE } from "../../../config.app";
import { findAmbassadorProductOrder } from "../../../shared/utils/productOrder.utils";
import { ambassadorOptionFromProductCode } from "../../../shared/utils/ambassador.utils";

const Container = styled.div`
  @media only screen and (min-width: 1000px) {
    grid-gap: 16px;
    grid-template-columns: max-content 1fr;
    h3 {
      text-align: right;
      align-self: start;
      margin-top: 0;
    }
`;

const PersonalStep = () => {
  const [findShopClient, {data}] = useLazyQuery<FindShopClient>(FindShopClientQuery);

  const {
    shopDetails: {
      firstName, name,
      phone, email,
      newsletter,
      promocode,
      ambassador
    },
    setPersonalField,
    updateClientData,
    updateAmbassadorship
  } = useShop();
  //
  // useEffect(() => {
  //   let shopClients = compact(data?.shopClients);
  //   if (shopClients.length > 0) {
  //     updateClientData(shopClients);
  //   }
  // }, [data]);

  useEffect(() => {
    let matchingClient = first(data?.clients);
    let matchingShopClient = first(data?.shopClients);

    let clientEmail = get(matchingClient, 'email');
    setPersonalField('clientEmail', clientEmail);

    if (matchingShopClient && !matchingClient) {
      updateClientData([matchingShopClient as FindShopClient_shopClients]);
    } else if (matchingClient) {
      updateClientData([{
        email: matchingClient.email,
        name: matchingClient.name,
        firstName: matchingClient.firstName,
        phone: matchingClient.phone || '',
        id: matchingShopClient ? matchingShopClient.id : 'new',
        newsletter: matchingShopClient ? matchingShopClient.newsletter : false,
      }]);
    }

    let ambassadorOption = undefined;
    let ambassadorProductOrder = findAmbassadorProductOrder(compact(flatten(map(matchingClient?.clientOrders, 'productOrders'))));
    if (ambassadorProductOrder?.product?.code) {
      ambassadorOption = ambassadorOptionFromProductCode(ambassadorProductOrder?.product?.code);
    }
    updateAmbassadorship(ambassadorOption || undefined);
  }, [data]);

  return <>
    <FormWrapper>
      <h2>Persoonlijke gegevens</h2>
      <p>Geef hieronder jouw persoonsgegevens in en kies je afhaalpunt/moment.</p>
      <Container>
        <FormSection>
          <h3>E-mailadres</h3>
          <FormInput
            name={'email'}
            placeholder={'E-mailadres'}
            value={email}
            onChange={(value) => setPersonalField('email', value)}
            onBlur={(value) => {
              if (value && value.indexOf('@') > -1) {
                findShopClient({variables: {email: value}});
              }
            }}
          />

          <h3>Naam</h3>
          <InlineFields grid={'1fr 2fr'}>
            <FormInput
              name={'firstName'}
              placeholder={'Voornaam'}
              onChange={value => setPersonalField('firstName', value)}
              value={firstName}
            />
            <FormInput
              name={'name'}
              placeholder={'Familienaam'}
              onChange={value => setPersonalField('name', value)}
              value={name}
            />
          </InlineFields>

          <h3>GSM / Tel.</h3>
          <FormInput
            name={'phone'}
            placeholder={'GSM / Tel.'}
            value={phone}
            onChange={(value) => setPersonalField('phone', value)}/>
          <h3>Nieuwsbrief</h3>
          <div>
            <FormCheckbox
              name={'newsletter'}
              value={newsletter}
              onChange={(value) => setPersonalField('newsletter', value)}
              label={'Ik wens updates van de veldwinkel te ontvangen via e-mail.'}
            />
          </div>
          <h3>Promocode</h3>
          <div>
            <FormInput
              name={'promocode'}
              placeholder={'Promocode'}
              onChange={value => setPersonalField('promocode', value)}
              value={promocode || undefined}
            />
            {
              promocode === PROMOCODE && <div className="my-2">
                <Message
                  style={{
                    border: 'solid #1ea97c',
                    borderWidth: '0 0 0 6px',
                    color: '#1ea97c'
                  }}
                  className="w-full justify-content-start"
                  severity="success"
                  content={<div>
                    <div className="text-xl font-light">Proficiat</div>
                    <div className="t">Je verzilverde zonet je kortingsbon t.w.v. <span className="font-bold">15 EUR!</span></div>
                  </div>}
                />
              </div>
            }
          </div>

          <Packaging/>

          <Delivery/>
        </FormSection>
      </Container>
    </FormWrapper>
  </>;
};

export default PersonalStep;
