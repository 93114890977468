import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { ProductPromoType, ProductUnit } from "../../../__generated__/types";
import { formatDoubleDigit } from "../../../../src/shared/utils/currency.utils";
import { useShop } from "../Shop.context";
import { compact, floor } from "lodash";
import { momentFromIso8601 } from "../../../../src/shared/utils/date.utils";
import moment from "moment";
import { ShopProduct } from "../Shop";
import classNames from "classnames";
import PriceTag from "./PriceTag";
import { findProductPriceForClientGroup, productIsAvailableAsAlternativeUnitFor, units } from "../../../shared/utils/product.utils";
import { vat } from "../../../../src/shared/utils/vat.utils";
import { translatedUnit } from "../../../shared/utils/unit.utils";
import ProductQuantityInput from "../../../shared/components/ProductQuantityInput";

interface ProductProps {
  product: ShopProduct;
  clientGroupId: string;
  inclVat: boolean;
  onAdd?: (productId: string, amount: number) => void;
}


const Container = styled.div`
  background-color: white;
  border: 1px solid #748F56;
  position: relative;

  display: grid;
  grid-template-areas:
      'promo'
      'name'
      'productImage'
      'price'
      'promoInfo'
      'orderAmount';
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content max-content;
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 125px max-content 1fr;
    grid-template-areas: 'promo promo promo' 'productImage name name' 'productImage price price' 'productImage promoInfo promoInfo' 'productImage orderAmount orderAmount';
    padding: 0 0 8px 0;
  }

  &.promo {
    border: 2px solid var(--invalid);
    background-color: var(--green-20);
  }

  .promoRibbon {
    background: var(--invalid);
    padding: 4px;
    font-weight: bold;
    color: white;
    font-size: 110%;
    text-align: center;

    position: relative;

    .icon-discount {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
    }
  }

  .product {
    border: 1px solid #748F56;
    display: grid;
    grid-template-areas: 'title title' 'image image' 'price price' 'order order';
    grid-template-rows: 70px 90px max-content max-content;
  }

  .image {
    align-self: center;
    justify-self: center;
    mix-blend-mode: multiply;
  }

  .title {
    padding: 0.5em;
    font-weight: bold;
    font-size: 1.2em;
    word-break: break-word;
  }

  .orderAmount {
    input {
      background-color: transparent;
      width: 50px;
      order: 2;
      border: 0;
      outline: none;
      box-shadow: none;
      padding: 0 0 2px 0;
      text-align: center;
    }

    border: 1px solid rgba(238, 122, 49, 0.36);
  }

  .p-inputnumber {
    &.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
      order: 1;
      padding-left: 3px;
      padding-right: 3px;
    }

    &.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
      order: 3;
      padding-left: 3px;
      padding-right: 3px;
    }

    .p-button.p-button-icon-only {
      width: auto;
    }

    button {
      &.p-button,
      &.p-button:enabled:active,
      &.p-button:enabled:hover,
      &.p-button:enabled:focus,
      &.p-button:enabled:visited {
        background-color: transparent;
        color: #EE7A31;
        border: 0;
        outline: none;
        box-shadow: none;
      }
    }
  }
`;

const Price = styled.div`
  justify-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  //display: grid;
  ////background-color: rgba(116,143,86, 0.5);
  //grid-template-columns: max-content 1fr;
  //
  //div {
  //  padding: 0.25em 1em;
  //
  //  color: white;
  //  color: rgba(116,143,86, 1);
  //
  //  .price{
  //    font-weight: bold;
  //    font-size: 1.2em;
  //  }
  //
  //  .unit {
  //    font-weight: normal;
  //  }
  //}
`;

const Order = styled.div`
  //display: grid;
  //grid-template-columns: 1fr max-content;
  //
  //button.add-to-cart {
  //  &.p-button,
  //  &.p-button:enabled:active,
  //  &.p-button:enabled:hover,
  //  &.p-button:enabled:focus,
  //  &.p-button:enabled:visited {
  //    background-color: #EE7A31;
  //    color: white;
  //    border: 0;
  //    outline: none;
  //    box-shadow: none;
  //    border-radius: 0;
  //    padding: 0;
  //
  //    span {
  //      font-size: 1.5em;
  //    }
  //  }
  //}
  //
  //.p-button.p-button-icon-only {
  //  width: 2em;
  //}
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 140px;
  object-fit: fill;
`;

const Product = (props: ProductProps) => {
  const {product} = props;
  const [orderAmount, setOrderAmount] = useState<number>(props.product.amount || 1);
  const {addToShoppingCart} = useShop();

  let availableAsAlternativeUnit = productIsAvailableAsAlternativeUnitFor(props.product, props.clientGroupId);

  let productPrice = findProductPriceForClientGroup(product, props.clientGroupId)?.value;
  if (props.inclVat && productPrice) {
    productPrice = productPrice * (1 + vat(product.vat));
  }

  let productPromoPrice = product.promoPrice;

  const activePromo = compact(product.productPromos)
    .find(promo => (!promo.endDate || momentFromIso8601(promo.endDate).isSameOrAfter(moment(), 'd')));

  let suffix = product.unit ? `${units[product.unit]}` : '';
  let suffix2 = translatedUnit(availableAsAlternativeUnit ? product.alternativeUnit : product.unit, orderAmount);

  if (activePromo && activePromo.type === ProductPromoType.PLUS_ONE && activePromo.secondValue && orderAmount >= activePromo.value) {

    const freeAmount = floor(orderAmount / activePromo.value) * activePromo.secondValue;

    suffix = `${suffix} + ${formatDoubleDigit(freeAmount)}${product.unit === ProductUnit.KILOGRAMS ? 'kg' : product.unit === ProductUnit.GRAMS ? 'g' : ''} gratis`;
  }

  useEffect(() => {
    if (activePromo && activePromo.type === ProductPromoType.PLUS_ONE && activePromo.secondValue) {
      setOrderAmount(activePromo.value);
    }
  }, [activePromo]);

  return <Container className={classNames({promo: activePromo})}>
    {!!activePromo && <div className={'promoRibbon'} style={{gridArea: 'promo'}}>
      {
        activePromo.type === ProductPromoType.DISCOUNT_PERCENTAGE
          ? `- ${activePromo.value * 100}% korting`
          : activePromo.type === ProductPromoType.PLUS_ONE
            ? `${formatDoubleDigit(activePromo.value)}${product.unit === ProductUnit.KILOGRAMS ? 'kg' : product.unit === ProductUnit.GRAMS ? 'g' : ''} + ${activePromo.secondValue && formatDoubleDigit(activePromo.secondValue)}${product.unit === ProductUnit.KILOGRAMS ? 'kg' : product.unit === ProductUnit.GRAMS ? 'g' : ''} gratis`
            : activePromo.type === ProductPromoType.FIXED_PRICE
              ? 'Verlaagd in prijs'
              : ''
      }
    </div>}
    {/*<div  className={'product shadow-1'} style={{zIndex: 1}}>*/}
    <div style={{gridArea: 'name'}}
      /*style={{gridArea: 'title'}}*/
         className={'title'}>
      <div>{product.name}</div>
    </div>

    <div style={{gridArea: 'productImage'}} className={'image'}>
      {product.image && <Image src={product.image}/>}
    </div>

    <Price style={{gridArea: 'price'}}>
      {productPrice && <PriceTag product={product} value={productPrice} rejected={!!productPromoPrice}/>}

      {productPromoPrice && <PriceTag product={product} value={productPromoPrice}/>}

      {/*{productPromoPrice && <PriceTag className={'promo'}>*/}
      {/*  <span className={'currency'} style={{gridArea: 'currency'}}>&euro;&nbsp;</span>*/}
      {/*  <span className={'decimal'}*/}
      {/*        style={{gridArea: 'decimal'}}>{productPromoPrice && formatDoubleDigitStrict(productPromoPrice).slice(0, -3)}</span>*/}
      {/*  <span className={'fraction'}*/}
      {/*        style={{gridArea: 'fraction'}}>{productPromoPrice && formatDoubleDigitStrict(productPromoPrice).slice(-3)}</span>*/}
      {/*  <span className={'unit'}*/}
      {/*        style={{gridArea: 'unit'}}>/ {product.amount !== 1 && product.amount} {product.unit && units[product.unit]}</span>*/}
      {/*</PriceTag>}*/}
    </Price>

    {props.onAdd && <Order style={{gridArea: 'orderAmount'}} className={'shadow-1'}>
      <ProductQuantityInput onSave={(value) => {
        if (value && props.onAdd && productPrice) {
          let updValue = (product.amount && availableAsAlternativeUnit && product.avgWeight) ? (value * product.avgWeight) / product.amount : value;
          if (updValue) {
            props.onAdd(product.id, updValue);
            setOrderAmount(updValue);
            addToShoppingCart(product, (updValue / (product.amount || 1)), props.clientGroupId);
          }
        }

      }} value={orderAmount} product={product} clientGroupId={props.clientGroupId}/>
      {/*<InputNumber*/}
      {/*  id="stacked"*/}
      {/*  value={orderAmount}*/}
      {/*  className={'orderAmount'}*/}
      {/*  onValueChange={(e: any) => setOrderAmount(e.value)}*/}
      {/*  showButtons*/}
      {/*  min={0.25}*/}
      {/*  buttonLayout="horizontal"*/}
      {/*  incrementButtonIcon={'pi pi-plus'}*/}
      {/*  decrementButtonIcon={'pi pi-minus'}*/}
      {/*  locale={'nl-NL'}*/}
      {/*  maxFractionDigits={2}*/}
      {/*  minFractionDigits={0}*/}
      {/*  step={(product.unit === ProductUnit.PIECE || product.unit === ProductUnit.BUNCH)*/}
      {/*    ? (product.amount || 1)*/}
      {/*    : ((product.amount && product.amount > 1) ? product.amount : 0.25)*/}
      {/*  }*/}
      {/*  suffix={` ${suffix2}`}*/}
      {/*/>*/}
      {/*<Button icon={'pi pi-shopping-cart'}*/}
      {/*        className={'add-to-cart p-button-text'}*/}
      {/*        type={'button'}*/}
      {/*        onClick={() => {*/}
      {/*            debugger;*/}
      {/*          if (props.onAdd && productPrice) {*/}
      {/*            let value = (product.amount && availableAsAlternativeUnit && product.avgWeight) ? (orderAmount * product.avgWeight) / product.amount : orderAmount;*/}
      {/*            props.onAdd(product.id, value);*/}
      {/*            addToShoppingCart(product, (value / (product.amount || 1)), props.clientGroupId);*/}
      {/*          }*/}
      {/*        }}*/}
      {/*/>*/}
    </Order>}
    {/*</div>*/}


  </Container>;
};

export default Product;
