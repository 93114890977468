import React from 'react';
import DeliveryLocationOptions from "./DeliveryLocationOptions";
import DeliveryDateOptions from "./DeliveryDateOptions";

interface DeliveryProps {

}


const Delivery = (props: DeliveryProps) => {
  return <>
    {/*<h2>Kies jouw afhaalpunt en moment</h2>*/}
    {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias amet esse. Amet aut doloremque dolores eum*/}
    {/*  inventore ipsam ipsum iusto libero, minima molestias nihil nisi perspiciatis quae quo sed.</p>*/}
    <DeliveryLocationOptions/>
    <DeliveryDateOptions/>
  </>;
};

export default Delivery;
