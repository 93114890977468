import styled from "styled-components";

export const WizardActions = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  .right {
    display: flex;
    grid-template-columns: 1fr max-content;
    grid-gap: 16px;
    align-items: center;
  }
`;

export const Fee = styled.div`
  p {
   margin-bottom: 0;
  }

  span {
    font-style: italic;
    font-size: 0.9em;
  }
`;
