import React from 'react';
import styled from "styled-components";
import { SubscriptionSubType, SubscriptionType } from "../../../shared/types/types";

interface CallToActionProps {
  type: string;
  label: string;
}

const Container = styled.div`
  padding: 32px 8px;
  background-color: var(--orange);
  text-align: center;
  a {
    border: 2px solid white;
    color: white;
    text-decoration: none;

    padding: 4px 8px;
    font-size: 1.2em;

    @media only screen and (min-width: 600px) {
      padding: 8px 32px;
      font-size: 1.5em;
    }

    //font-weight: bold;
    border-radius: 8px;
    text-transform: uppercase;
  }
`;

const CallToAction = (props: CallToActionProps) => {
  return <Container>
    <a href={'https://www.mijnboer.be/groentegeweld/inschrijven/'+props.type}>{props.label}</a>
  </Container>;
};

export default CallToAction;
