import React from 'react';
import { Content } from "../../App";

interface GeneralConditionsProps {

}

const GeneralConditions = (props: GeneralConditionsProps) => {
  return <Content>
    <div className="text-base font-bold mb-2">Algemene voorwaarden</div>

    <div className="text-xs">
      <div className="font-bold mt-2">Ondernemingsgegevens</div>
      <div className="mb-2">
        Groentegeweld VVZRL <br/>
        Weeldestraat 168 <br/>
        23812 Weelde <br/>
        BE0746.361.154 <br/><br/>
        Contactgegevens: Bel +32 494 66 43 46 of mail info@groentegeweld.be
      </div>


      <div className="font-bold mt-2">
        <div className="font-bold mt-2">Artikel 1: Algemene bepalingen</div>
      </div>
      De webshop van Groentegeweld, met maatschappelijke zetel te Weeldestraat 168 – 2381 Weelde , BTW BE0746.361.154,
      biedt klanten de mogelijkheid om de producten uit de webwinkel online aan te kopen.
      <br/>Wanneer de klant zijn online bestelling bevestigt, verklaart deze akkoord gegaan te zijn met deze algemene
      voorwaarden.
      <br/>Enkel het Belgisch recht is van toepassing op deze site. In geval van een geschil zijn enkel de rechtbanken
      van het arrondissement Turnhout bevoegd.

      <div className="font-bold mt-2">Artikel 2: Prijzen</div>
      Alle vermelde prijzen zijn uitgedrukt in EURO, inclusief BTW voor particuliere klanten, exclusief BTW voor
      professionele klanten
      en alle andere verplicht door de klant te dragen kosten of belastingen.
      <br/>
      Indien leverings-, reservatie- of administratieve kosten worden aangerekend, wordt dit apart vermeld (zie artikel
      5).
      <br/>
      De opgave van de prijs slaat uitsluitend op de artikelen zoals deze woordelijk worden omschreven. De bijhorende
      foto’s zijn decoratief bedoeld en kunnen elementen bevatten die niet inbegrepen zijn in de prijs.

      <div className="font-bold mt-2">Artikel 3: Aanbod</div>
      Ondanks het feit dat de online catalogus en de webshop met de grootst mogelijke zorgvuldigheid worden
      samengesteld, is het toch mogelijk dat de aangeboden informatie onvolledig is, materiële fouten bevat, of niet
      up-to-date is. Kennelijke vergissingen of fouten in het aanbod binden Groentegeweld niet. Groentegeweld is wat de
      juistheid en volledigheid van de aangeboden informatie slechts gehouden tot een middelenverbintenis. Groentegeweld
      is
      in geen geval aansprakelijk ingeval van manifeste materiële fouten.
      <br/>
      Wanneer de klant specifieke vragen heeft over bv. beschikbaarheid, leveringstermijn of
      leveringswijze, verzoeken wij de klant om vooraf contact op te nemen via info@groentegeweld.be.
      <br/>
      Het aanbod geldt steeds zolang de voorraad strekt en kan te allen tijde worden aangepast of ingetrokken door
      Groentegeweld. Groentegeweld kan niet aansprakelijk gesteld worden voor het niet beschikbaar zijn van een product.
      Indien
      onder voorwaarden geschiedt, wordt dit nadrukkelijk in het aanbod vermeld.

      <div className="font-bold mt-2">Artikel 4: Online aankopen</div>
      Nadat de klant de bestelling bevestigde en verstuurde, ontvangt deze een automatisch gegenereerde e-mail met
      bevestiging van zijn bestelling. Op deze bevestiging kan de klant opmaken of de betaling reeds volbracht werd of
      indien hij deze nog moet in orde brengen. De klant is verplicht de gegevens vermeld op deze bestelbon te
      controleren en Groentegeweld op de hoogte te brengen van eventuele fouten of gebreken.
      <br/>
      De klant heeft de keuze tussen de volgende betaalwijzen
      <br/>
      <ul>
        <li>via de betaalknop van Bancontact op de website</li>
        <li>via bankoverschrijving</li>
        <li>via betaling bij ophaling</li>
      </ul>
      <br/>
      Groentegeweld is gerechtigd een bestelling te weigeren ingevolge een ernstige tekortkoming van de klant met
      betrekking
      tot bestellingen waarbij de klant betrokken is.

      <div className="font-bold mt-2">Artikel 5: Ophalen of verzenden van aankopen</div>
      Afhalen op onze afhaalpunten heeft de voorkeur en kan zonder extra kosten. Dit is mogelijk tijdens de
      voorziene afhaaluren op de afhaalpunten. Voor een up-to-date overzicht kan u terecht op www.groentegeweld.be.
      <br/>
      Elke zichtbare beschadiging en/of kwalitatieve tekortkoming van een artikel of andere tekortkoming bij de
      levering, moeten door de klant binnen 5 werkdagen na ontvangst worden gemeld aan info@groentegeweld.be.

      <div className="font-bold mt-2">Artikel 6: Eigendomsvoorbehoud</div>
      De geleverde artikelen blijven tot op het moment van gehele betaling door de klant, de exclusieve eigendom van
      Groentegeweld.
      <div className="font-bold mt-2">Artikel 7: Herroepingsrecht & retourneren</div>
      De klant heeft het recht om binnen een termijn van 7 kalenderdagen en voor betaling van zijn aankoop zonder opgave van reden de overeenkomst te
      herroepen.
      <br/>
      Om het herroepingsrecht uit te oefenen, moet de klant Groentegeweld met een schriftelijk bewijs op de hoogte
      stellen
      van zijn beslissing de aankoop te herroepen. Dit kan via e-mail naar info@groentegeweld.be. Daarna heeft de klant
      ook
      maximum 7 kalenderdagen de tijd om de artikelen terug te bezorgen in hun oorspronkelijke staat en verpakking.
      Artikelen die zijn beschadigd of waarbij de verpakking ontbreekt, worden niet terug betaald.
      <br/>
      De directe kosten van het terugzenden van de goederen komen voor rekening van de klant.
      <div className="font-bold mt-2">Artikel 8: Klantendienst</div>
      Groentegeweld is bereikbaar op het telefoonnummer +32 494 66 43 46 of via e-mail op info@groentegeweld.be.
      Eventuele klachten kunnen hieraan gericht worden.
      <br/>
      Klachten of betwistingen dienen binnen de 7 dagen na levering gemeld te worden per aangetekend schrijven op
      straffe van verval. In geval de klacht gegrond wordt bevonden is onze aansprakelijkheid beperkt tot de omruiling
      van de goederen. Elke aansprakelijkheid van de verkopen beperkt zich tot het daadwerkelijk betaalde bedrag van de
      bestelling.
      <br/>
      <div className="font-bold mt-2">Artikel 9: Privacy</div>
      Groentegeweld respecteert de Algemene Verordening Gegevensbescherming en de Belgische Privacywet van 30 juli 2018.
      <br/>
      De door u meegedeelde persoonsgegevens zullen enkel gebruikt worden voor volgende doeleinden: het verwerken van de
      bestelling en indien gewenst: versturen van nieuwsbrieven, reclame- en/of marketingdoeleinden. De rechtsgronden
      zijn de uitvoering van de overeenkomst, de toestemming, het vervullen van wettelijke en reglementaire
      verplichtingen en/of het gerechtvaardigd belang de onderneming.
      <br/>
      De klant beschikt over een wettelijk recht op inzage en eventuele correctie, aanvulling of verwijdering van de
      eigen persoonsgegevens. De klant mag in een aantal gevallen opgesomd in de AVG ook vragen om de verwerking van
      persoonsgegevens te beperken. De klant kan zich eveneens verzetten tegen de verwerking van de eigen
      persoonsgegevens als daar ernstige en legitieme redenen voor zijn die onze noodzaak om deze gegevens te verwerken,
      overstijgen. Via een schriftelijke, gedateerde en ondertekende aanvraag aan Willem Van Pelt, Groentegeweld,
      Weeldestraat 168 te Weelde, kan de klant gratis de eigen persoonsgegevens in digitale en leesbare vorm
      opvragen en/of aan andere verantwoordelijken over laten dragen.
      <br/>
      In geval van gebruik van gegevens voor direct marketing: de klant kan zich kosteloos verzetten tegen het gebruik
      van persoonsgegevens voor direct marketing en hoeft daarvoor geen reden op te geven.
      <br/>
      Om uw rechten uit te oefenen, kan u zich richten tot Willem Van Pelt, Weeldestraat 168, 2381 Weelde.
      <br/>
      Wij behandelen uw gegevens als vertrouwelijke informatie en zullen die niet doorgeven, verhuren of verkopen aan
      derden.
    </div>
  </Content>;
};

export default GeneralConditions;
