import React from 'react';
import styled from "styled-components";
import { HarvestListData_fields, HarvestListData_fields_rotationAreas } from "../../../__generated__/types";
import { compact, flattenDeep, last } from 'lodash';
import { cultivation_bestFitName } from "../../../shared/utils/domain/cultivation.utils";

interface FieldProps {
  field: HarvestListData_fields;
}

const Container = styled.div`
  background-color: #EEE;
  padding: 1rem;

  h3 {
    color: var(--green);
  }
`;

const Title = styled.div`
  color: var(--green);
  font-size: 1.2rem;
  font-weight: 400;
`;

const Block = styled.div`
  .cultivations {
    margin-left: 1rem;
  }

  .harvest-0 {
    color: red;
  }
  .harvest-1 {
    color: yellow;

  }
  .harvest-2 {
    color: green;
  }
`;

const hasCultivations = (rotationArea: HarvestListData_fields_rotationAreas) => {
  return flattenDeep(compact(rotationArea.plots).map(r => compact(r.cultivations))).length > 0;
};

const Field = (props: FieldProps) => {
  return <Container>
    <Title>{props.field.name}</Title>
    {
      compact(props.field.rotationAreas).map(rotationArea => {
        let plots = compact(rotationArea.plots);
        if (hasCultivations(rotationArea)) {
          return <Block>
            <span>{plots.length === 1 ? 'Bed' : 'Blok'} {rotationArea.number}</span>
            <div className={'cultivations'}>
              {plots.map(plot => {
                return compact(plot.cultivations).map(cultivation => {
                  let lastHarvestEvent = last(cultivation.events);
                  const flag = lastHarvestEvent && lastHarvestEvent.metadata.flag;

                  return <div>
                    <i
                      className={'pi pi-circle-on harvest-' + flag}/>{compact(cultivation.varieties).map(variety => cultivation_bestFitName(cultivation.crop, variety.variety)).join(', ')}
                  </div>
                });
              })}
            </div>
          </Block>;
        } else {
          return <div/>;
        }
      })
    }
  </Container>;
};

export default Field;
