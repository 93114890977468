import React from 'react';
import { StringParam, useQueryParam } from "use-query-params";
import { useQuery } from "@apollo/react-hooks";
import { Invoice, MembershipByEmail_memberships_invoices_payments } from "../../__generated__/types";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import styled from "styled-components";
import { asDdMmYyyy, momentFromIso8601 } from "../../../src/shared/utils/date.utils";
import { formatCurrency } from "../../../src/shared/utils/currency.utils";
import MembershipInvoicePdf from "../../shared/pdf/membershipInvoicePdf";
import { isMobile } from 'react-device-detect';
import { InvoiceQuery } from "../../shared/queries/invoice.gql";

interface BillingProps {

}

const Container = styled.div`
  padding: 40px 5vw;
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-gap: 4vh;

  a {
    color: #EE7A31;
  }
`;

const Payments = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2vh;

`;

const Payment = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 2vw;
`;

const Billing = (props: BillingProps) => {
  const [id] = useQueryParam('id', StringParam);
  const {data} = useQuery<Invoice>(InvoiceQuery, {variables: {id}});

  let invoice = data?.invoice;
  if (invoice) {
    // let invoices = (membership.invoices || []).filter(f => membership.season === '2021-2022' || (membership.season === '2020-2021' && f.filename.startsWith('F-PF-')));
    let payments: MembershipByEmail_memberships_invoices_payments[] = [];
    if (invoice) {
      payments = invoice.payments || [];
    }

    let filename = invoice ? invoice.filename.replace('.pdf', '') : '';
    return <Container>
      <div>
        Dag
        <br/>
        <br/>

        {!filename.startsWith('F-PF-') && <div>
          Hier kan je jouw factuur raadplegen voor het volgende oogstseizoen 2021-2022.
          Gelieve deze factuur te betalen voor de opgegeven vervaldatum.
        </div>}
        {filename.startsWith('F-PF-') && <div>
          <p>
            Hier kan je jouw <strong>pro forma factuur</strong> raadplegen voor het volgende oogstseizoen
            2021-2022. <br/>
            Het factuurbedrag is gebaseerd op <strong>jouw gekozen tarief van afgelopen oogstseizoen</strong> 2020-2021,
            dit tarief werd
            aangepast naar onze huidige prijsvork.
          </p>
          <p>
            Prima zo? Perfect, bij <strong>betaling</strong> is je <strong>inschrijving definitief</strong>!
          </p>
        </div>}
        <br/>
        <p><strong>Betalingsgegevens:</strong></p>
        <br/>
        {payments.length > 1 && <div>
          Gespreide betaling:
          <br/>
          (Wij deden een voorstel tot gespreide betaling. Zie jij deze toch liever anders, neem dan gerust even <a
          href={'mailto:info@groentegeweld.be?subject=Info%20gespreide%20betaling%20' + filename}>contact</a> met ons
          op.
          <br/>
          <br/>
        </div>}
        <Payments>
          {payments.map(payment => (
            <Payment>
              <div> Rekeningnummer:</div>
              <div><strong>BE46 5230 8118 5136</strong><br/></div>
              <div>Mededeling:</div>
              <div><strong>{filename}</strong><br/></div>
              <div>Bedrag:</div>
              <div><strong>{invoice && formatCurrency(payment.amount)}</strong><br/></div>
              <div>Vervaldag:</div>
              <div><strong>{invoice && asDdMmYyyy(momentFromIso8601(payment.dueDate))}</strong><br/></div>
            </Payment>
          ))}
        </Payments>
        {isMobile && <PDFDownloadLink document={<MembershipInvoicePdf data={invoice}/>} fileName={`${invoice.filename}`}>
          {({ blob, url, loading, error }) =>
            loading ? 'Je factuur wordt aangemaakt...' : 'Download mijn factuur'
          }
        </PDFDownloadLink>}
        {payments.length === 1 && <div>
          <br/>
          Had je liever gespreid betaald? Neem dan even <a
          href={'mailto:info@groentegeweld.be?subject=Info%20gespreide%20betaling%20' + filename}>contact</a> met ons op
          en we bekijken wat er mogelijk is.
        </div>}
      </div>
      <div>
        {!isMobile && <PDFViewer style={{height: '70vh', width: '90vw'}}>
          <MembershipInvoicePdf data={invoice}/>
        </PDFViewer>}
      </div>
    </Container>;
  } else {
    return <div/>;
  }
};

export default Billing;
