import React from 'react';
import { formatCurrencySign } from "../../../../../src/shared/utils/currency.utils";
import { ShoppingCartItem } from "./ShoppingCart";
import styled from "styled-components";
import classNames from "classnames";
import { activePromo } from "../../../../shared/utils/product.utils";
import { ProductPromoType } from "../../../../__generated__/types";

interface PriceColumnProps {
  item: ShoppingCartItem;
}

const Container = styled.div`
  white-space: nowrap;

  &.promo {
    color: var(--invalid);
    font-weight: bold;
  }

  .promoLabel {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 80%
  }
`;

const PriceColumn = (props: PriceColumnProps) => {
  const {item} = props;

  let hasActivePromo = activePromo(item.product, [
    ProductPromoType.FIXED_PRICE,
    ProductPromoType.DISCOUNT_PERCENTAGE,
  ]);
  return <Container
    className={classNames({promo: hasActivePromo})}
  >
    <div>{formatCurrencySign(item.priceIncl)}</div>
    {!!hasActivePromo && <div className={'promoLabel'}>promo</div>}
  </Container>;
};

export default PriceColumn;
