import React from 'react';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

interface AccountCompletionProps {

}

const AccountCompletion = (props: AccountCompletionProps) => {
  return <div>
    <h2>Vervolledig jouw account</h2>
    <p>Welkom bij Groentegeweld! <br/> Om verder te gaan, hebben we nog enkele gegevens van jou nodig.</p>

    <div className="field">
      <label htmlFor="firstname" className="col-12 mb-2 md:col-2 md:mb-0">Voornaam</label>
      <InputText id="firstname" className="col-12 md:col-10"/>
    </div>
    <div className="field">
      <label htmlFor="lastname" className="col-12 mb-2 md:col-2 md:mb-0">Achternaam</label>
      <InputText id="lastname" className="col-12 md:col-10"/>
    </div>
    <Button
      label={'Bevestigen'}
    />

  </div>;
};

export default AccountCompletion;
