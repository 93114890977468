import React, { createContext, ReactNode, useContext, useState } from "react";
import { chain, compact, first, floor, get, omit, values } from "lodash";
import { AmbassadorOption, DeliveryLocation, FindClientForShop_clients, FindShopClient_shopClients, GetProducts_products, LinkShopClientAndMembership, ProductPromoType, ProductUnit, ShopClientCreateOneInput, ShopOrderCreateInput, ShopOrderItemCreateWithoutShopOrderInput, UpsertShopOrder, UpsertShopOrderVariables, Vat } from "../../__generated__/types";
import moment from "moment";
import { useMutation } from "@apollo/react-hooks";
import { LinkShopClientAndMembershipMutation, UpsertShopOrderMutation } from "./Shop.gql";
import { asDayFullDdMmYyyy, asIso8601, momentFromIso8601 } from "../../../src/shared/utils/date.utils";
import { ShopProduct } from "./Shop";
import { activePromo, findProductPriceForClientGroup, findProductPriceForClientGroupCode, ProductPriceType, ProductPromo, units } from "../../shared/utils/product.utils";
import emailjs from "emailjs-com";
import { paymentDescription, totalPrice } from "../../shared/utils/Shop.utils";
import { deliveryLocations } from "../../../src/shared/utils/deliveryLocation.utils";
import { formatCurrencySign, formatDoubleDigit } from "../../../src/shared/utils/currency.utils";
import { ShoppingCartItem } from "./components/ShoppingCart/ShoppingCart";
import { ambassadorDiscountPercentage } from "../../shared/utils/ambassador.utils";
import { vat } from "../../../src/shared/utils/vat.utils";

export interface ShoppingCart {
    items: { [key: string]: ShoppingCartItem };
}

export interface ShopDetails {
    cart: ShoppingCart;

    shopClientId?: string;
    name: string;
    firstName: string;
    email: string;
    phone: string;
    promocode: string | null;
    ambassador?: AmbassadorOption;
    clientEmail?: string;

    newsletter: boolean;
    deliveryLocation?: DeliveryLocation;
    deliveryDate?: string;
    paymentConfirmed?: boolean;
    packaging?: boolean;
}

interface ShopContextValue {
    shopDetails: ShopDetails;
    submitted: boolean;
    submitShopOrder: () => void;

    addToShoppingCart: (productId: ShopProduct, amount: number, clientGroupId: string) => void;
    removeFromShoppingCart: (productId: string) => void;

    setPersonalField: (field: keyof ShopDetails, fieldValue: any) => void;
    updateClientData: (shopClients: {
        email?: string | null;
        name?: string | null;
        firstName?: string | null;
        phone?: string | null;
        id?: string | null;
        newsletter?: boolean | null;
    }[]) => void,
    updateAmbassadorship: (ambassador: AmbassadorOption | undefined) => void,
}

export const DEFAULT_VALUE: ShopContextValue = {
    // shopDetails: {
    //   cart: {
    //     items: {
    //       ckmv5ursu4m400774dqxi7cyr: {
    //         productId: "ckmv5ursu4m400774dqxi7cyr",
    //         amount: 2,
    //         totalPriceIncl: 20,
    //       },
    //       ckr3zsdbk0kvk0775wvq18oah: {
    //         productId: "ckr3zsdbk0kvk0775wvq18oah",
    //         amount: 1,
    //         totalPriceIncl: 20,
    //       },
    //       ckmv5urkt4lyw07742kvoaqly: {
    //         productId: "ckmv5urkt4lyw07742kvoaqly",
    //         amount: 1,
    //         totalPriceIncl: 20,
    //       },
    //       ckmv5urkg4ly407742aflptkj: {
    //         productId: "ckmv5urkg4ly407742aflptkj",
    //         amount: 2,
    //         totalPriceIncl: 20,
    //       }
    //     }
    //     // items: {}
    //   },
    //   name: "Willem Van Pelt",
    //   firstName: "Willem",
    //   email: "willem.vanpelt@me.com",
    //   phone: "+32494664346",
    //   newsletter: true,
    //   deliveryLocation: DeliveryLocation.WEELDE,
    //   deliveryDate: asIso8601(moment("06-08-2022", "DD-MM-YYYY")),
    //   paymentConfirmed: undefined,
    // },
    shopDetails: {
        cart: {
            items: {}
        },
        shopClientId: undefined,
        name: '',
        firstName: '',
        email: '',
        phone: '',
        promocode: null,
        newsletter: true,
        deliveryLocation: undefined,
        deliveryDate: undefined,
        paymentConfirmed: undefined,
        packaging: false,
    },
    submitted: false,
    submitShopOrder: () => {
    },
    addToShoppingCart: () => {
    },
    removeFromShoppingCart: () => {
    },
    setPersonalField: () => {
    },
    updateClientData: () => {
    },
    updateAmbassadorship: () => {
    },
};

export type ShopProviderProps = {
    children: ReactNode;
    shopWizardContextValue?: ShopContextValue;
};

const ShopProvider = (props: ShopProviderProps) => {
    const [contextValue, setContextValue] = useState<ShopContextValue>(props.shopWizardContextValue || DEFAULT_VALUE);
    const [upsertShopOrder] = useMutation<UpsertShopOrder>(UpsertShopOrderMutation);
    const [linkShopClientAndMembership] = useMutation<LinkShopClientAndMembership>(LinkShopClientAndMembershipMutation);

    const submitShopOrder = () => {
        let shopOrderCreateInput: ShopOrderCreateInput | undefined = undefined;

        const {
            cart,
            phone,
            deliveryLocation,
            newsletter,
            name,
            email,
            firstName,
            deliveryDate,
            shopClientId,
            promocode,
            packaging,
        } = contextValue.shopDetails;
        if (deliveryDate &&
            deliveryLocation
        ) {
            let shopClient: ShopClientCreateOneInput;
            if (shopClientId && shopClientId !== 'new') {
                shopClient = {
                    connect: {
                        id: shopClientId
                    }
                }
            } else {
                shopClient = {
                    create: {
                        email: email,
                        phone: phone,
                        name: name,
                        firstName: firstName,
                        newsletter: newsletter,
                    }
                };
            }
            shopOrderCreateInput = {
                orderDate: asIso8601(moment()),
                deliveryDate: deliveryDate,
                deliveryLocation: deliveryLocation,
                shopClient: shopClient,
                extraPackaging: packaging,
                promocode,
                shopOrderItems: {
                    create: values(cart.items).map(item => {
                        let itemCreate: ShopOrderItemCreateWithoutShopOrderInput = {
                            amount: item.amount,
                            priceIncl: item.priceIncl,
                            product: {
                                connect: {
                                    id: item.product.id
                                }
                            }
                        };

                        if (item.currentPromo?.id) {
                            itemCreate = {
                                ...itemCreate,
                                currentPromo: {
                                    connect: {
                                        id: item.currentPromo.id,
                                    }
                                }
                            };
                        }

                        return itemCreate;
                    }),
                }
            }
        }

        if (shopOrderCreateInput) {
            const variables: UpsertShopOrderVariables = {
                id: 'new',
                shopOrderCreateInput,
                shopOrderUpdateInput: {},
            }

            upsertShopOrder({variables})
                .then((d) => {
                    // let shopClientId = d.data?.upsertShopOrder.shopClient.id;
                    // if (contextValue.shopDetails.shopClientId !== shopClientId) {
                    //     linkShopClientAndMembership({
                    //         variables: {
                    //             membershipId: contextValue.shopDetails.membershipId,
                    //             shopClientId,
                    //         }
                    //     }).then(r => console.log(r))
                    // }

                    //IF there is a new client created and there is a membershipId, link them together

                    let deliveryLocationData = deliveryLocations.find(d => d.value === deliveryLocation);
                    let ambassadorDiscount = contextValue.shopDetails.ambassador ? ambassadorDiscountPercentage(contextValue.shopDetails.ambassador) : 0;
                    emailjs.send('service_8ch8mgd', 'template_veldwinkel', {
                        to: email,
                        name: firstName + ' ' + name,
                        paymentDescription: paymentDescription(deliveryDate, name),
                        totalAmount: formatCurrencySign(totalPrice(values(contextValue.shopDetails.cart.items), contextValue.shopDetails.packaging, ambassadorDiscount, contextValue.shopDetails.promocode)),
                        deliveryDate: deliveryDate && asDayFullDdMmYyyy(momentFromIso8601(deliveryDate)),
                        shopHours: deliveryLocationData && deliveryLocationData.shopHours,
                        deliveryLocation: deliveryLocationData && `${deliveryLocationData.name}<br /> ${deliveryLocationData.address} <br /> ${deliveryLocationData.city}`,
                        products: chain(cart.items)
                            .values()
                            .map(item => {
                                const {amount, unit, productPromos} = item.product;
                                if (amount) {
                                    let shopAmount = amount * item.amount;
                                    let freeAmount = 0;
                                    const activePlusOnePromo = compact(productPromos)
                                        .find(promo => promo.type === ProductPromoType.PLUS_ONE && (!promo.endDate || momentFromIso8601(promo.endDate).isSameOrAfter(moment(), 'd')));

                                    if (activePlusOnePromo && activePlusOnePromo.secondValue) {
                                        freeAmount = floor(shopAmount / activePlusOnePromo.value) * activePlusOnePromo.secondValue;
                                    }

                                    return `${item.product.name} - ${item.product.amount && formatDoubleDigit(item.amount * item.product.amount)} ${item.product.unit && units[item.product.unit]} ${freeAmount > 0 ?
                                        `+ ${formatDoubleDigit(freeAmount)} ${unit === ProductUnit.KILOGRAMS ? 'kg' : unit === ProductUnit.GRAMS ? 'g' : ''} gratis` : ''}<br/>--- ${formatCurrencySign(item.priceIncl)}`;
                                }
                                return null;
                            })
                            .compact()
                            .value()
                            .join('<br/><br/>'),
                    })
                        .then((result) => {
                            setContextValue(value => ({
                                ...value,
                                submitted: true,
                                subscriptionDetails: DEFAULT_VALUE.shopDetails,
                            }))
                        }, (error) => {
                            //setSending(false);
                        });
                })
                .catch((reason) => {
                    console.error(reason);
                    emailjs.send('service_8ch8mgd', 'template_baitgh7', {
                        to: email,
                        name: firstName + ' ' + name,
                        reason: JSON.stringify(reason),
                    })
                        .then((result) => {
                            setContextValue(value => ({
                                ...value,
                                submitted: true,
                                shopDetails: DEFAULT_VALUE.shopDetails,
                            }))
                        }, (error) => {
                            //setSending(false);
                        });
                });
        }
    };

    const addToShoppingCart = (product: ShopProduct, amount: number, clientGroupId: string) => {
        let price = findProductPriceForClientGroup(product, clientGroupId)?.value
        if (price && product.amount) {
            price = price * (1 + vat(product.vat));
            let updatedAmount = get(contextValue, `shopDetails.cart.items[${product.id}].amount`, 0) + amount;

            if (product.promoPrice) {
                price = product.promoPrice
            }

            const priceIncl = (updatedAmount * price);
            let activePromoId = activePromo(product)?.id;
            let shoppingCartItem: ShoppingCartItem = {
                product: product,
                amount: updatedAmount,
                priceIncl,
            };

            if (activePromoId) {
                shoppingCartItem = {...shoppingCartItem, currentPromo: {id: activePromoId}};
            }

            const updatedContextValue: ShopContextValue = {
                ...contextValue,
                shopDetails: {
                    ...contextValue.shopDetails,
                    cart: {
                        ...contextValue.shopDetails.cart,
                        items: {
                            ...contextValue.shopDetails.cart.items,
                            [product.id]: shoppingCartItem
                        }
                    }
                }
            };
            setContextValue(updatedContextValue);
        }
    };

    const removeFromShoppingCart = (productId: string) => {
        const newVar: ShopContextValue = {
            ...contextValue,
            shopDetails: {
                ...contextValue.shopDetails,
                cart: {
                    ...contextValue.shopDetails.cart,
                    items: {
                        ...omit(contextValue.shopDetails.cart.items, productId),
                    }
                }
            }
        };
        setContextValue(newVar);
    };

    const setPersonalField = (field: keyof ShopDetails, fieldValue: any) => {
        setContextValue(value => {
            return ({
                ...value,
                shopDetails: {
                    ...value.shopDetails,
                    [field]: fieldValue,
                }
            });
        });
    };

    const updateClientData = (shopClients: {
        email?: string | null;
        name?: string | null;
        firstName?: string | null;
        phone?: string | null;
        id?: string | null;
        newsletter?: boolean | null;
    }[]) => {
        const shopClient = first(shopClients);
        if (shopClient) {
            setContextValue(value => {
                return ({
                    ...value,
                    shopDetails: {
                        ...value.shopDetails,
                        shopClientId: shopClient.id || undefined,
                        email: shopClient.email || '',
                        name: shopClient.name || '',
                        firstName: shopClient.firstName || '',
                        phone: shopClient.phone || '',
                        newsletter: !!shopClient.newsletter,
                    }
                });
            });
        }
    };
    const updateAmbassadorship = (ambassador: AmbassadorOption | undefined) => {
        setContextValue(value => {
            return ({
                ...value,
                shopDetails: {
                    ...value.shopDetails,
                    ambassador,
                }
            });
        });
    };

    return <ShopContext.Provider value={{
        ...contextValue,
        submitShopOrder,
        addToShoppingCart,
        removeFromShoppingCart,
        setPersonalField,
        updateClientData,
        updateAmbassadorship,
    }}>
        {props.children}
    </ShopContext.Provider>
};

const ShopContext = createContext(DEFAULT_VALUE);

const useShop = () => useContext(ShopContext);

export { ShopProvider, useShop };
