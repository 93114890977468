import gql from "graphql-tag";

export const CampaignEntryCreateMutation = gql`
  mutation CampaignEntryCreate($data: CampaignEntryCreateInput!) {
  createCampaignEntry (
    data: $data
  ) {
    id
  }
}`;
