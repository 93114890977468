import React from 'react';
import { Button } from "primereact/button";

interface PotluckProps {

}

const Potluck = (props: PotluckProps) => {
  return <div className="m-3 flex flex-column align-items-center justify-content-center">
    <div className="w-full text-left text-2xl color-green font-bold mb-3">Potluck BBQ</div>
    <div className="pb-8">
      <Button
        outlined
        className="text-2xl"
        label={'Schrijf mij in'}
        onClick={() => {
          window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSeypsslvhBl4p3GCCbqjkpOInbLqhGEClo4wrPPZKgjRH1Hzg/viewform?usp=sf_link";
        }}
      />
    </div>

    <div
      className="square bg-center bg-no-repeat w-full"
      //style={{backgroundImage: "url('./potluck.jpg')", backgroundSize: "contain"}}
    >
      <img src="potluck.jpg"/>
    </div>


  </div>;
};

export default Potluck;
