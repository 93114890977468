import React, { useEffect } from 'react';
import classNames from "classnames";
import { useShop } from "../../Shop.context";
import { useField } from "formik";
import DeliveryLocations from "../../../SubscriptionWizard/components/FormulaStep/components/DeliveryLocations";

const DeliveryLocationOptions = () => {
  const {setPersonalField, shopDetails: {deliveryLocation}} = useShop();
  let field = useField('deliveryLocation');
  const meta = field[1];
  const helper = field[2];

  const isFormFieldValid = () => !!((meta.touched) && meta.error);

  useEffect(() => {
    helper.setValue(deliveryLocation);
  }, [deliveryLocation]);

  return <>
    <h3 className={classNames({'p-invalid': (meta.touched && meta.error)})}>Afhaalpunt</h3>
    <div>
      {isFormFieldValid() && <small className={'p-error'}>{meta.error}</small>}
      <DeliveryLocations
        onClick={(deliveryLocationOption) => {
          setPersonalField('deliveryLocation', deliveryLocationOption.value);
          helper.setValue(deliveryLocationOption.value);
        }}
        static={false}
        value={deliveryLocation}
        field={field}
      />
    </div>
  </>;
};

export default DeliveryLocationOptions;
