import React, { useEffect, useRef, useState } from 'react';
import { InputText } from "primereact/inputtext";
import { compact, isEmpty, values } from "lodash";
import { GetProducts, GetProducts_products, GetProductsVariables, ProductStatus } from "../../../__generated__/types";
import styled from "styled-components";
import { useShop } from "../Shop.context";
import { Toast } from "primereact/toast";
import { Badge } from "primereact/badge";
import ShoppingCartContainer from "./ShoppingCart/ShoppingCartContainer";
import { OverlayPanel } from "primereact/overlaypanel";
import ProductList from "../../../shared/ProductList/ProductList";
import { useQuery } from "@apollo/react-hooks";
import { GetProductsQuery } from "../../../shared/queries/product.gql";
import { findProductPriceForClientGroup, productIsAvailableAsAlternativeUnitFor } from "../../../shared/utils/product.utils";

// const Products = styled.div`
//   display: grid;
//   grid-gap: 8px;
//   grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
//   //grid-template-columns: 1fr;
//   margin: 0.5em;
//
//   @media only screen and (max-width: 800px) {
//     grid-template-columns: 1fr;
//     margin: 0;
//   }
// `;

const MarketWrapper = styled.div`
  @media only screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 8fr minmax(390px, 3fr);
    grid-column-gap: 1.5em;

    > *:first-child {
      padding-right: 1.5em;
      border-right: 1px solid rgba(116, 143, 86, 0.3);
    }
  }

  .p-toast {
    opacity: 1;
  }
`;

const MarketTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;

  align-items: center;

  #cartButton {
    display: none;
  }

  @media only screen and (max-width: 800px) {
    #cartButton {
      display: block;
    }
  }
`;

const ProductsStep = () => {
  const [filteredProducts, setFilteredProducts] = useState<GetProducts_products[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const {shopDetails: {cart}, addToShoppingCart} = useShop();

  const toast = useRef<Toast | null>(null);

  const variables: GetProductsVariables = {
    clientGroupCode: "standaard",
    where: {
      status: ProductStatus.ACTIVE,
      productAvailabilities_some: {
        endDate: null,
        clientGroup: {code: "standaard"}
      }
    }
  };
  const {data} = useQuery<GetProducts>(GetProductsQuery, {
    variables
  });

  useEffect(() => {
    let products = compact(data?.products);
    if (!isEmpty(searchValue)) {
      setFilteredProducts(products
        .filter(product => product.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
      );
    } else {
      setFilteredProducts(products);
    }
  }, [searchValue, data]);

  let nrOfItemsInCart = values(cart.items).length;
  const op = useRef<any>();

  return <MarketWrapper>
    <Toast ref={toast} position={'bottom-right'}/>
    <OverlayPanel ref={op}>
      <ShoppingCartContainer/>
    </OverlayPanel>
    <div>
      <MarketTitle>
        <h1 className={'color-orange'}>Het veld</h1>
        <i id={'cartButton'}
           className={'pi icon-wheelbarrow p-overlay-badge'}
           onClick={(e) => op.current?.toggle(e)}
           style={{fontSize: '2rem', cursor: 'pointer', marginRight: '10px'}}>
          {nrOfItemsInCart ? <Badge value={nrOfItemsInCart} style={{backgroundColor: 'var(--orange)'}}></Badge> : ''}
        </i>
      </MarketTitle>
      <div className={'p-fluid mb-4'}>
          <span className="p-input-icon-left" style={{width: '100%'}}>
                        <i className="pi pi-search"/>
                        <InputText
                          value={searchValue}
                          onChange={(e: any) => {
                            setSearchValue(e.target.value);
                          }}
                          placeholder="Zoek op het veld"
                        />
          </span>
      </div>
      {data?.clientGroup?.id && <ProductList
        showIn={"currency"}
        clientGroup={data.clientGroup}
        products={filteredProducts}
        onAdd={(productId, quantity) => {
          if (data?.clientGroup?.id) {
            let product = filteredProducts.find(product => product.id === productId);
            let clientGroupId = data.clientGroup.id;
            const productPrice = findProductPriceForClientGroup(product, clientGroupId)?.value
            if (product && productPrice) {
              let availableAsAlternativeUnit = productIsAvailableAsAlternativeUnitFor(product, clientGroupId);
              //let value = (product.amount && availableAsAlternativeUnit && product.avgWeight) ? (quantity * product.avgWeight) / product.amount : quantity;
              //props.onAdd(product.id, value);
              addToShoppingCart(product, (quantity / (product.amount || 1)), clientGroupId);
            }
          }
        }}
      />}

      {/*<Products>*/}
      {/*  {filteredProducts.map(product => {*/}
      {/*      return <div>*/}
      {/*        <Product*/}
      {/*          inclVat*/}
      {/*          clientGroupId={"ckbuxfzg307wz0774ooelvsqa"}*/}
      {/*          key={product.id}*/}
      {/*          product={product}*/}
      {/*          onAdd={() => {*/}
      {/*            toast.current && toast.current.show({*/}
      {/*              severity: 'success',*/}
      {/*              summary: product.name + ' toegevoegd.',*/}
      {/*              //detail: product.name + ' toegevoegd.',*/}
      {/*              life: 3000,*/}
      {/*            });*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>;*/}
      {/*    }*/}
      {/*  )}*/}
      {/*</Products>*/}
    </div>
    <ShoppingCartContainer/>
  </MarketWrapper>;
};

export default ProductsStep;
