import React, { useEffect } from 'react';
import { InputText } from "primereact/inputtext";
import { useField } from "formik";
import classNames from "classnames";
import { InputMask } from "primereact/inputmask";

interface FormInputProps {
  name: string;
  mask?: string;
  value: string | undefined | null;
  placeholder: string;
  onChange: (value: string | undefined | null) => void;
  onBlur?: (value: string) => void;
  validateWithoutTouch?: boolean;
}

const FormInput = (props: FormInputProps) => {
  const [field, meta, helper] = useField(props.name);

  const isFormFieldValid = () => !!((props.validateWithoutTouch || meta.touched) && meta.error);
  const getFormErrorMessage = () => {
    return isFormFieldValid() && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
      helper.setValue(props.value);
  }, [props.value]);

  return <div>
    {props.mask
      ? <InputMask
        {...field}
        placeholder={props.placeholder}
        className={classNames({'p-invalid': isFormFieldValid()})}
        mask={props.mask}
        // value={props.value || undefined}
        onBlur={(e) => {
          props.onBlur && props.onBlur(e.target.value);
        }}
        onChange={(e) => {
          helper.setValue(e.target.value);
          props.onChange(e.target.value);
        }}/>
      : <InputText
        {...field}
        placeholder={props.placeholder}
        className={classNames({'p-invalid': isFormFieldValid()})}
        // value={props.value || undefined}
        onBlur={(e) => {
          props.onBlur && props.onBlur(e.target.value);
        }}
        onChange={(e) => {
          helper.setValue(e.target.value);
          props.onChange(e.target.value);
        }}/>
    }

    {getFormErrorMessage()}
  </div>;
};

export default FormInput;
