import React, { useEffect, useState } from 'react';
import { useQuery } from "@apollo/react-hooks";
import { HarvestListData, HarvestListData_fields } from "../../__generated__/types";
import { compact, flattenDeep } from "lodash";
import { HarvestListQuery } from "./HarvestList.gql";
import styled from 'styled-components';
import Field from "./components/Field";

interface HarvestListProps {

}

const Container = styled.div`
    width: 1200px;
    max-width: 100vw;
    margin: 0 auto;
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1vh;
`;

const HarvestList = (props: HarvestListProps) => {
  const {data} = useQuery<HarvestListData>(HarvestListQuery);

  const [fields, setFields] = useState<HarvestListData_fields[]>([]);

  useEffect(() => {
    if (data) {
      setFields(compact(data.fields));
    }

  }, [data]);

  return <Container>
    <h1>Oogstlijst</h1>
    <Fields>
      {fields.map(field => {
        if (flattenDeep(compact(field.rotationAreas).map(r => compact(r.plots).map(p => compact(p.cultivations)))).length > 0) {
          return <Field field={field}/>
        } else {
          return <div/>;
        }
      })}
    </Fields>
  </Container>;
};

export default HarvestList;
