import styled from "styled-components";

export const FormWrapper = styled.div`

  .p-checkbox {
    margin-right: 10px;
  }

  .p-inputnumber {
    &.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
      order: 1;
      padding-left: 3px;
      padding-right: 3px;
    }
    &.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
      order: 3;
      padding-left: 3px;
      padding-right: 3px;
    }

    .p-button.p-button-icon-only {
      width: auto;
    }

    button {
    &.p-button,
    &.p-button:enabled:active,
    &.p-button:enabled:hover,
    &.p-button:enabled:focus,
    &.p-button:enabled:visited {
      background-color: transparent;
      color: #EE7A31;
      border: 0;
      outline: none;
      box-shadow: none;
    }
  }

  .p-inputtext.error,
  .p-inputtext.error:focus,
  .p-inputtext.error:hover {
    border-color: var(--error);
    background-color: var(--error-10);
  }

  .error {
    color: var(--error);
    font-weight: bold;
  }

  .p-checkbox.error .p-checkbox-box {
    border-color: var(--error);
  }

  .required:not(.before) {
    &:after {
      padding-left: 4px;
      content: '*';
    }
  }

  .required.before {
    &:before {
      padding-right: 4px;
      content: '*';
    }
  }

  .p-inputtext {
    border: 0;
    border-bottom: 1px solid rgb(134, 105, 73);
    color: rgb(134, 105, 73);
    border-radius: 0;
    box-shadow: none;
    outline: none;

    &:enabled:focus {
      outline: none;
      box-shadow: none;
      border-bottom: 1px solid #748F56;
    }
    @media only screen and (min-width: 1000px) {
      &.secondaryEmail {
        margin-left: 30px;
        width: calc(50% - 30px);
      }
    }

    &.secondaryEmail {
        margin-left: 30px;
        width: 90%;
      }
  }

  .p-slider .p-slider-range, .p-slider .p-slider-handle {
    background-color: #EE7A31;
    border: 0;
  }

  .p-selectbutton .p-button.p-highlight,
   .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover{
    background-color: #748F56;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }

  .p-selectbutton.error {
    border: 1px solid rgba(238,122,49,0.90);
    border-radius: 4px;
  }

  .check {
    margin-bottom: 2vh;
  }
`;

export const SliderLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 4px;
`;

export const SliderWrapper = styled.div`
      display: grid;
      align-items: center;
      justify-content: center;
      grid-gap: 16px;
      margin: 32px 0 16px 0;

  > div.values {
    @media only screen and (max-width: 1000px) {
        margin-bottom: 16px;
    }
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 4px;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    > span.label {
      font-weight: 400;
      text-align: right;
    }

    > span.secondary {
      font-weight: 400;
      font-style: italic;
    }

    > span.primary {
      font-size: 1.2rem;
    }
  }
`;


export const InlineFields = styled.div<{ grid: string }>`
  display: grid;
  grid-template-columns: ${props => props.grid};
  grid-gap: 10px;
  align-items: center;
`;

export const FormSection = styled.div`
  @media only screen and (max-width: 1000px) {
    h3 {
      margin-top: 32px;
      margin-bottom: 0;
    }

    //> span {
    //  padding: 10px;
    //  display: inline-block;
    //}
  }

  > div.label {
    display: none;
  }

  display: grid;
  grid-gap: 10px;
  @media only screen and (min-width: 1000px) {
    grid-template-columns: max-content 1fr;
    align-items: center;

    > h2 {
        grid-column: 1 / span 2;
    }

    > div.label {
      text-align: right;
      display: block;
    }
  }

  input, textarea {
    width: 100%;
  }

  margin-bottom: 10px;
  padding-bottom: 10px;

  p {
    margin: 0;
  }
`;

export const PriceInput = styled.div`
  border: 1px solid #748F56;
  padding: 8px;
`;

export const PriceInputInfo = styled.div`
  h3, p, li {
    font-size: 90%;
  }
`;
