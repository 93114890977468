import React from 'react';
import { formatCurrencySign } from "../../../../../src/shared/utils/currency.utils";
import { ShoppingCartItem } from "./ShoppingCart";
import { totalPrice } from "../../../../shared/utils/Shop.utils";
import { useShop } from "../../Shop.context";
import { values } from "lodash";
import { PACKAGING_OPTIONS } from "../PersonalStep/Packaging";
import { ambassadorDiscountPercentage } from "../../../../shared/utils/ambassador.utils";
import { PROMOCODE } from "../../../../config.app";

interface PriceFooterCellProps {
  items: ShoppingCartItem[];
}

const PriceFooterCell = (props: PriceFooterCellProps) => {
  const {shopDetails} = useShop();
  let packagingValue = PACKAGING_OPTIONS.find(packaging => packaging.value === shopDetails.packaging)?.price;

  let ambassadorDiscount = shopDetails.ambassador ? ambassadorDiscountPercentage(shopDetails.ambassador) : 0;
  let subTotal = totalPrice(values(shopDetails.cart.items), false, 0, null);
  let totalPriceWithPackaging = totalPrice(values(shopDetails.cart.items), shopDetails.packaging, ambassadorDiscount, shopDetails.promocode);
  return <>
    <div>{formatCurrencySign(subTotal)}</div>
    {shopDetails.packaging && <div>{formatCurrencySign(packagingValue && packagingValue > 0 ? packagingValue : 0)}</div>}
    {ambassadorDiscount > 0 && <>
      <div>
        - {formatCurrencySign(subTotal   * ambassadorDiscount)}
      </div>
    </>}
    {shopDetails.promocode === PROMOCODE && <>
      <div>{formatCurrencySign(-15)}</div>
    </>}
    {!!packagingValue && <>
      <div>{formatCurrencySign(totalPriceWithPackaging)}</div>
    </>}
  </>;
};

export default PriceFooterCell;
